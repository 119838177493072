const newArrivals = [{
        name: "Canon Cam",
        image: require("../../assets/images/camera.png").default,
        link: "/"

    },
    {
        name: "Appliance",
        image: require("../../assets/images/ptv.png").default,
        link: "/Appliance"

    }, {
        name: "Smart Watch",
        image: require("../../assets/images/smartwatch.png").default,
        link: "/"

    }, {
        name: "Chairs",
        image: require("../../assets/images/chairx.png").default,
        link: "/"

    }, {
        name: "Toys",
        image: require("../../assets/images/toys.png").default,
        link: "/"

    }, {
        name: "Electronics",
        image: require("../../assets/images/mobile.png").default,
        link: "/"

    }, {
        name: "computers",
        image: require("../../assets/images/computers.png").default,
        link: "/computers"

    }, {
        name: "Travel",
        image: require("../../assets/images/travel.png").default,
        link: "/travel"

    },
]

export default newArrivals;
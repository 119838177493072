import IFormInput, { Fields } from "../../interfaces/formInput";

export const loginFormInputFields: Fields = {
    fields: [
        {
            name: "email",
            id: "email",
            placeholder: "Email",
            type: "email",
        },
        {
            name: "password",
            id: "password",
            placeholder: "Password",
            type: "password",
        }
    ] as Array<IFormInput>,
}

export interface ILoginFields {
    email: string,
    password: string,
}
export default loginFormInputFields;
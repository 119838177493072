import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
// import "swiper/components/pagination/pagination.min.css";

import SwiperCore, { Autoplay, Lazy, Navigation } from "swiper";

import laptopbg from "../../assets/images/laptopbg.jpeg";
import chairbg from "../../assets/images/chairbg.jpeg";

// install Swiper modules
SwiperCore.use([Lazy, Navigation, Autoplay]);

function SwiperBg() {
  return (
    <div className="mt-2">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <img loading="lazy" src={laptopbg} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img loading="lazy" src={chairbg} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default SwiperBg;

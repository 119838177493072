import { ButtonSuccess } from "./form/button";
import Input from "./form/input";
import Error from "./form/error";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { routePaths } from "../config/routePaths";
import { getIn, useFormik } from "formik";
import registerFormInputFields, {
  IRegisterFields,
} from "../constants/form/registerForm";
import { useEffect, useState } from "react";
import SocialMediaAuthIcons from "./socialMediaAuthIcons";
import api from "../apis/axios";
import { ErrorHandler } from "../utils/ErrorHandler";
import { toast } from "react-toastify";
import { redirectTo } from "../pages/application";

const schema = yup
  .object({
    email: yup.string().email().required().min(5).max(50),
    // lastName: yup.string().required().min(2).max(20),
    // firstName: yup.string().required().min(2).max(20),
    password: yup
      .string()
      .required()
      .min(8)
      .max(20)
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .not(["password"]),
    // confirmPassword: yup
    //   .string()
    //   .required()
    //   .min(8)
    //   .max(20)
    //   .matches(
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    //   )
    //   .oneOf([yup.ref("password"), null], "Passwords must match"),
    // phoneNumber: yup
    //   .string()
    //   .required()
    //   .min(8)
    //   .max(14)
    //   .matches(
    //     /^\+[1-9][0-9\s.-]{7,14}$/,
    //     "Invalid phone number. Make Sure its in an international format"
    //   ),
    // dateOfBirth: yup.string().required().min(2).max(20),
  })
  .required();

const RegisterForm = () => {
  const [disable, setDisable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(null);

  const _signUp = async (values: IRegisterFields) => {
    setIsSubmitting(true);

    try {
      const response = await api({
        method: "post",
        url: "Account/Register",
        data: values,
      });

      setIsSubmitting(false);

      const {
        data: { isSuccessful, message },
      } = response;

      if (isSuccessful) {
        redirectTo(routePaths.login, toast.success, message);
      } else {
        toast.error(message);
      }

      // console.log(response.data);
    } catch (e: any) {
      setIsSubmitting(false);
      // console.log(e);
      const err = ErrorHandler(e);
      // console.log(err);

      if (typeof err === "object") {
        err?.forEach((error: string) => {
          toast.error(error);
        });
      } else {
        toast.error(err);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      // lastName: "",
      // firstName: "",
      password: "",
      // confirmPassword: "",
      // phoneNumber: "",
      // dateOfBirth: new Date(),
    },
    validationSchema: schema,
    onSubmit: (values: IRegisterFields) => {
      // console.log({ values });
      _signUp(values);
    },
  });

  useEffect(() => {
    if (isSubmitting) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [isSubmitting]);

  // const dispatch = useAppDispatch();

  return (

    <>

      <div className="lg:tw-w-1/2 tw-w-full tw-flex tw-items-center bg-light tw-justify-center md:tw-px-16 tw-px-0 tw-z-0">
        <div
          className="tw-absolute lg:tw-hidden tw-z-10 tw-inset-0 bg-light tw-bg-no-repeat tw-bg-cover tw-items-center"      >
          <div className="tw-absolute tw-bg-bush tw-opacity-60 tw-inset-0 tw-z-0" />
        </div>


        <div className="tw-w-full tw-py-6 tw-z-20 bg-light">
          <div className="tw-px-4 lg:tw-px-0">
            <h1 className="tw-text-3xl tw-text-gray-900 tw-my-6">
              Welcome to Adehot
            </h1>
            <p>Creacte your Account</p>
          </div>

          <form onSubmit={formik.handleSubmit} className=" tw-w-full tw-px-4 lg:tw-px-0">
            {registerFormInputFields.fields.map((fields, key) => {
              return (
                <div key={key} className="tw-pb-2 tw-pt-4">
                  <Input
                    name={fields.name}
                    type={fields.type}
                    placeholder={fields.placeholder}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={getIn(formik.touched, fields.name)}
                    error={getIn(formik.errors, fields.name)}
                    value={getIn(formik.values, fields.name)}
                  />
                  <Error
                    touched={getIn(formik.touched, fields.name)}
                    error={getIn(formik.errors, fields.name)}
                  />
                </div>
              );
            })}
            <div className="tw-text-left tw-my-1 tw-text-gray-900 hover:tw-underline hover:tw-text-gray-800">
              <Link to={routePaths.login}>One of us? Login</Link>
            </div>
            <div className="tw-pb-2 tw-pt-4 d-flex justify-content-center  ">
              <ButtonSuccess disabled={disable} type="submit" content=" Sign up " />
            </div>
            <SocialMediaAuthIcons />
          </form>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;

import { Link } from 'react-router-dom';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header/Header';
import { routePaths } from '../config/routePaths';
import { AiFillEye } from 'react-icons/ai'

//tables body components
const Cellbody = (props) => {
    return (
        <>
            <tr>
                <th scope="row mt-2">1</th>
                <td>{props.id}</td>
                <td>{props.date} </td>
                <td>{props.price} </td>
                <td>{props.itemsPurchased} </td>
                <td>
                    <div className="badge badge-warning p-2">
                        {props.status}
                    </div>
                </td>
                <td>
                    <div className="icons">
                        <Link to={routePaths.order}>
                            <span><AiFillEye className="m-auto" />  Details</span>
                        </Link>
                    </div>
                </td>
            </tr>
        </>
    )
}

export const History = () => {

    return (
        <>

            {/* headers secton */}
            <Header />


            <div className="container mt-5">

                <h1>Orders </h1>

                {/* tables goes here */}
                <div class="table-responsive">
                    <table class="table mt-5 mb-5">
                        <thead>
                            <tr>
                                <th scope="col">S/N</th>
                                <th scope="col">ID</th>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Items Purchased</th>
                                <th scope="col">Order Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {/* table body components goes here */}
                            <Cellbody
                                id='#FK235D2'
                                date='30-12-2021'
                                price=' ₦3500'
                                itemsPurchased='5'
                                status='pending'
                            />
                        </tbody>
                    </table>
                </div>
            </div>


            <Footer />

        </>
    )


}

import { useState } from 'react';
import MainLayout from '../components/layouts/main';

function Preferences() {
  const user = localStorage.getItem('user');

  const choices = [
    {
      id: 1,
      name: 'fashion',
      isSelected: false,
    },
    {
      id: 2,
      name: 'Game Accessories',
      isSelected: false,
    },
    {
      id: 3,
      name: 'Mens Wear',
      isSelected: false,
    },
    {
      id: 4,
      name: 'Laptops',
      isSelected: false,
    },
    {
      id: 5,
      name: 'Grocery',
      isSelected: false,
    },
    {
      id: 6,
      name: 'Appliance',
      isSelected: false,
    },
    {
      id: 7,
      name: 'Mobile',
      isSelected: false,
    },
    {
      id: 8,
      name: 'Travels',
      isSelected: false,
    },
    {
      id: 9,
      name: 'Toys',
      isSelected: false,
    },
    {
      id: 10,
      name: 'For kids',
      isSelected: false,
    },
    {
      id: 11,
      name: 'Baby Products',
      isSelected: false,
    },
    {
      id: 12,
      name: 'Electronics',
      isSelected: false,
    },
  ];

  const [preference, setpreference] = useState(choices);
  // eslint-disable-next-line no-unused-vars
  const [choice, setchoice] = useState([]);

  const updateChoice = (selection, id) => {
    setchoice([...selection]);
    checkSelected(id);
  };

  const checkSelected = (id) => {
    setpreference(
      preference.map((item, index) => {
        if (item.id === id) {
          if (item.isSelected === true) {
            item.isSelected = false;
          } else {
            item.isSelected = true;
          }
        }
        return item;
      })
    );
  };

  return (
    <MainLayout hideCategory>
      <div className="container pt-5">
        <h4 id="welcome">
          Welcome <span className="text-success">{user ? user : 'Obiabo'}</span>
        </h4>
        <p className="py-3">
          Select at least 5 categories of product you are likely to buy
        </p>
        <div className="row">
          {preference ? (
            preference.map((item, index) => (
              <div className="col-md-3">
                <button
                  className={
                    'outline_btn btn-block ' +
                    (item.isSelected ? 'bg-success text-light' : '')
                  }
                  onClick={(e) => updateChoice(item.name, item.id)}
                  style={{outline:'none'}}
                >
                  {item.name}
                  {item.isSelected ? (
                    <i className="fa fa-check text-success ml-2 mt-1 text-light"></i>
                  ) : (
                    ''
                  )}
                </button>
              </div>
            ))
          ) : (
            <div>loading peferences</div>
          )}
        </div>
        <div className="col-md-6 d-flex mt-3 float-right">
          <button className="btn   btn-block">Skip</button>
          <button className="btn btn-success  btn-block">Continue</button>
        </div>
      </div>
    </MainLayout>
  );
}

export default Preferences;

import React, { useState } from 'react';
import '../../assets/styles/main.css'
import { Container, Row, Col } from 'reactstrap'
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useEffect } from 'react';
import { _getProducts } from '../../redux/slices/productsSlice';
// import Checkout from '../../pages/checkout'
// import {Link} from 'react-router-dom'
import Task from './task'
import { decreaseQuantity, increaseQuantity } from '../../redux/slices/cartSlice';
const Cartpanel = (props: { quantity: number, status: string, productId: number, cartId: number, pricetag: string | number | null }) => {

    // const [quantity] = useState<number>(props.quantity)
    const [item_name, setItemName] = useState<string | null>(null);
    const [image, setImage] = useState<string | null>(null);
    const [description, setDescription] = useState<string | null>(null);

    //increment the option
    const add = () => {
        if (props.quantity <= 100) {
            dispatch(increaseQuantity({ cartId: props.cartId }))
        }
    }

    //deduct 
    const deduct = () => {
        if (props.quantity > 1) {
            dispatch(decreaseQuantity({ cartId: props.cartId }))
        }
    }

    const { data } = useAppSelector(state => state.products);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (data.length > 0) {
            let productIndex = data.findIndex((product) => { return product.id === props.productId; })
            let product = data[productIndex]
            // console.log(productIndex, product)
            setItemName(product?.name || "")
            setImage(product?.image || "")
            setDescription(product?.description?.replace(/(([^\s]+\s\s*){15})(.*)/, "$1…") || "") // trim first 15 words
            // product = data.filter(x => x.productId === props.productId)
        } else {
            dispatch(_getProducts)
        }
    }, [data, props.productId, dispatch])
    return (
        <>
            <main className='mt-3'>
                <Container>
                    <div className="cart-card">
                        <div className="cart-container">
                            <Row>
                                <Col>
                                    <div className={`product-img ${props.status && props.status === "loading" ? "skeleton tw-h-36" : ""}`}>
                                        <img loading="lazy" src={image || ""} alt={item_name || "loading"} width='150' />
                                    </div>
                                </Col>
                                <Col>
                                    <div className='description'>
                                        <h3 className={props.status === "loading" || !item_name ? "skeleton skeleton-title" : "tw-text-lg sm:tw-text-xl lg:tw-text-2xl"}>{`${item_name}`}</h3>
                                        <h5 className={props.status === "loading" || !props.pricetag ? "skeleton skeleton-text" : "mb-1 tw-font-bold"}>Unit Price: <b>₦{props.pricetag || "0000"}</b></h5>
                                        <h5 className={props.status === "loading" || !description ? "skeleton skeleton-text" : "mb-2"}>{description}</h5>
                                        <div className="lg-show">
                                            <Task cartId={props.cartId} />
                                            <div className="quantity mt-3">
                                                <button className={`btn btn-default mr-2`} disabled={props.quantity <= 1} onClick={deduct}>-</button>
                                                <h4 className={`m-auto ${props.status && props.status === "loading" && !props.quantity ? "skeleton skeleton-text" : ""}`}>{props.quantity}</h4>
                                                <button className={`btn btn-default ml-2`} disabled={props.quantity >= 100} onClick={add}>+</button>
                                            </div>
                                        </div>
                                        {/* Display the price when on smaller screens */}
                                        <div className="sm-show">
                                            <h5>Total: <b>₦{props.quantity * parseFloat((props.pricetag ? props.pricetag.toString() : "1")) || "0000"}</b></h5>
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={12} md={12} lg={4}>
                                    <div style={{ fontSize: '20px' }} className='float-right price-col lg-show'>
                                        <span className={`${props.status && props.status === "loading" && !props.pricetag ? "skeleton skeleton-text" : ""}`}>
                                            <h3>Total: <b>₦{props.quantity * parseFloat((props.pricetag ? props.pricetag.toString() : "1")) || "0000"}</b></h3>
                                        </span>
                                    </div>
                                    <div className="sm-show">
                                        <Task cartId={props.cartId} />
                                        <div className="quantity mt-3">
                                            <button className={`btn btn-default mr-2`} disabled={props.quantity <= 1} onClick={deduct}>-</button>
                                            <h4 className={`m-auto ${props.status && props.status === "loading" && !props.quantity ? "skeleton skeleton-text" : ""}`}>{props.quantity}</h4>
                                            <button className={`btn btn-default ml-2`} disabled={props.quantity >= 100} onClick={add}>+</button>
                                        </div>
                                        {/* varition */}
                                        {/* <div className="dropdown">
                                            <button className="btn btn-default dropdown-toggle mt-4" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Item Color
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <ul>
                                                    <li className="dropdown-item">Black</li>
                                                    <li className="dropdown-item">White</li>
                                                    <li className="dropdown-item">Blue</li>
                                                    <li className="dropdown-item">Grey</li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </main>
        </>
    )
}

export default Cartpanel
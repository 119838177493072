import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { getCartItems } from "../../apis/requests/cart";


export type ProductEmbedded = {
    id: number;
    name: string;
    image: string;
    price: number;
    description: string;
}

export type CartData = {
    id: number;
    userId: number;
    productId: number;
    productPrice: number;
    // product: ProductEmbedded;
    quantity: number;
}

type CartSlice = {
    status: "idle" | "loading" | "succeeded" | "failed";
    error: string | null;
    data: Array<CartData>;
    quantity: number;
    total: number;
}


export const _getCartItems: any = createAsyncThunk<any, { rejectValue: any }>(
    'cart/getAll',
    async (data: any, { rejectWithValue }) => {
        return getCartItems();
    }
);

// export const _addToCart: any = createAsyncThunk<any, { rejectValue: any }>(
//     'cart/addItem',
//     async (data: any, { rejectWithValue }) => {
//         return addToCart(data);
//     }
// );

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        status: "idle",
        error: null,
        data: [],
        quantity: 0,
        total: 0,
    } as CartSlice,
    reducers: {
        addToCart: (state: CartSlice, action: PayloadAction<CartData>) => {
            try {
                if (state.quantity <= 100) {
                    state.error = null;
                    state.status = "succeeded";
                    let { data } = current(state)
                    let cartItem = data.filter(x => x.productId === action.payload.productId)
                    // console.log(cartItem.length > 0)

                    if (cartItem.length > 0) {
                        let cartIndex = data.findIndex((cart) => { return cart.productId === action.payload.productId; })
                        let cart = { ...data[cartIndex] }
                        // cart.quantity = cart.quantity + action.payload.quantity;
                        cart.quantity += 1;
                        cart.id = action.payload.id;
                        if (cart.quantity > 100) { cart.quantity = 100 };

                        // console.log("cartIndex", "cart")
                        // console.log(cartIndex, cart)

                        state.data[cartIndex] = cart;

                        // state.data = data;
                    } else {
                        state.quantity += 1
                        state.data.push(action.payload)
                    }
                    state.total += action.payload.productPrice * 1
                }
            } catch (err: any) {
                // console.log(err.message)
            }
        },
        updateQuantity: (state: CartSlice, action: PayloadAction<{ cartId: number, quantity: number }>) => {
            try {
                const { data } = current(state)
                let cartIndex = data.findIndex((cart) => { return cart.id === action.payload.cartId; })
                let cart = { ...data[cartIndex] }
                state.total -= cart.quantity * cart.productPrice
                cart.quantity = action.payload.quantity
                state.total = cart.quantity * cart.productPrice
                state.data[cartIndex] = cart;
            } catch (err) {
                //console.error(err.message)
            }
            console.table(current(state.data));
        },
        decreaseQuantity: (state: CartSlice, action: PayloadAction<{ cartId: number, decrementValue?: number }>) => {
            try {
                const { data } = current(state)
                let cartIndex = data.findIndex((cart) => { return cart.id === action.payload.cartId; })
                let cart = { ...data[cartIndex] }

                cart.quantity = cart.quantity - (action.payload.decrementValue || 1);

                if (cart.quantity < 1) cart.quantity = 1;
                state.data[cartIndex] = cart;

                state.total -= cart.productPrice * (action.payload.decrementValue || 1)

            } catch (err) {
                //console.error(err.message)
            }
            console.table(current(state.data));
        },
        increaseQuantity: (state: CartSlice, action: PayloadAction<{ cartId: number, incrementValue?: number }>) => {
            try {
                const { data } = current(state)
                let cartIndex = data.findIndex((cart) => { return cart.id === action.payload.cartId; })
                let cart = { ...data[cartIndex] }
                cart.quantity = cart.quantity + (action.payload.incrementValue || 1);
                if (cart.quantity > 100) {
                    cart.quantity = 100;
                }

                state.total += cart.productPrice * (action.payload.incrementValue || 1)
                state.data[cartIndex] = cart;
            } catch (err) {
                //console.error(err.message)
            }
            console.table(current(state.data));
        },
        clearCart: (state: CartSlice) => {
            state.quantity = 0;
            state.data = [];
            state.total = 0;
            state.quantity = 0;
            state.error = null;
            state.status = "succeeded";
        },
        logoutCart: (state: CartSlice) => {
            let { data } = current(state);
            data.forEach((item) => {
                item.userId = 0;
            })
            state.data = data;
        },
        loginCart: (state: CartSlice, action: PayloadAction<number>) => {
            let { data } = current(state);
            // console.log(action.payload)
            data.forEach((item) => {
                item.userId = action.payload
            })
            state.data = data;
        },
        removeFromCart: (state: CartSlice, action: PayloadAction<number>) => {
            if (action.payload > 0) {
                const { data } = current(state)
                state.data = data.filter(data => data.id !== action.payload);
                let cartIndex = data.findIndex((cart) => { return cart.id === action.payload; })
                let cart = { ...data[cartIndex] }
                state.total -= cart.productPrice * cart.quantity;
                state.quantity -= 1;
                state.error = null;
                state.status = "succeeded";
            }
        }
    },
    extraReducers: {
        [_getCartItems.pending]: (state: CartSlice) => {
            state.status = "loading";
            state.error = null;
            // state.data = [];
        },
        [_getCartItems.fulfilled]: (state: CartSlice, action: PayloadAction<any>) => {
            state.status = "succeeded";
            state.data = action.payload.data;
            state.error = null;
            state.quantity = action.payload.data.length || 0;
            let total = 0;
            action.payload.data.forEach((cartData: any) => {
                total += (cartData?.productPrice || 1) * (cartData?.quantity || 1);
            });
            state.total = total;
        },
        [_getCartItems.rejected]: (state: CartSlice, action: any) => {
            state.status = "failed";
            state.error = (action.payload || action.error.message) || "Something went wrong!!";
            // console.log(action)
            // state.data = [];
        },
    }
})

// export const getCartState = (state: CartSlice) => state?.data;
export default cartSlice.reducer;
export const { addToCart, removeFromCart, decreaseQuantity, increaseQuantity, clearCart, updateQuantity, logoutCart, loginCart } = cartSlice.actions;
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./styles.css";
import logo from "../../../assets/images/logo.png";
import Search from "./Search";
import Country from "./Country";
import Log from "./Log";
import Cart from "./Cart";
import { GiHamburgerMenu } from "react-icons/gi";
import { routePaths } from "../../../config/routePaths";
import Sidebar from "../../home/Sidebar";
// import Order from "../../../pages/order"

function Header() {
  const [isopen, setisopen] = useState(false);

  const togglenav = () => {
    setisopen((prev) => !prev);
  };


  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 150) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })


  return (
    <>
      <header>
        <Sidebar isopen={isopen} togglenav={togglenav} />
        <div className="head px-3">
          <Link to={routePaths.home}>
            <img
              loading="lazy"
              src={logo}
              id="head-logo"
              className="img-fluid"
              alt="Adehot Logo"
            />
          </Link>
          <div className="d-none d-sm-none d-md-block hold-search">
            <Search />
          </div>
          <div className="d-flex justify-content-around align-items-center larger-holder">
            <Country className="d-sm-none d-none d-md-block" />
            <Log />
            <Cart />
          </div>
        </div>
        <div className={`small_screen_header tw-transition-all tw-duration-500 tw-ease-in-out ${scrolled ? "fixed-top" : ""} tw-bg-white-smoke-1 tw-shadow-sm tw-bg-opacity-90 px-3 pt-2 pb-3`}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-around align-items-center">
              <GiHamburgerMenu
                className=" mr-2"
                size={28}
                onClick={(e) => togglenav()}
              />
              <Link to={routePaths.home}>
                <img
                  loading="lazy"
                  src={logo}
                  id="head-logo"
                  className="img-fluid"
                  alt="Adehot Logo"
                />
              </Link>
            </div>
            <div className="d-flex justify-content-around align-items-center">
              <Log />
              <Cart />
            </div>
          </div>
          <Search />
        </div>
        <nav className="navbar navbar-expand-md navbar-light">
          <button
            className="navbar-toggler d-none"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <GiHamburgerMenu
                  className="mt-2 towhite"
                  size={25}
                  onClick={(e) => togglenav()}
                />
              </li>
              <li className="nav-item active mx-3">
                <Link
                  className="nav-link"
                  id="towhite"
                  to={routePaths.products}
                >
                  Customer Services
                </Link>
              </li>
              <li className="nav-item active mx-3">
                <Link
                  className="nav-link towhite"
                  id="towhite1"
                  to={routePaths.products}
                >
                  Gift Cards
                </Link>
              </li>
              <li className="nav-item active mx-3">
                <Link
                  className="nav-link towhite"
                  id="towhite2"
                  to={routePaths.products}
                >
                  Cyptocurrency
                </Link>
              </li>
              <li className="nav-item active mx-3">
                <Link
                  className="nav-link towhite"
                  id="towhite3"
                  to={routePaths.products}
                >
                  Sell Stuff
                </Link>
              </li>

            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;

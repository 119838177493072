import React, { useEffect } from "react";
import IPage from "../../interfaces/page";
import logging from "../../config/logging";
import AuthSideImage from "../../components/authSideImage";
import LoginForm from "../../components/loginForm";
import { useAppSelector } from "../../redux/store";
import { toast } from "react-toastify";
import { redirectTo } from "../application";

const LoginPage: React.FunctionComponent<IPage> = (props) => {
  const { error, user, token } = useAppSelector((state) => state.auth);

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
    document.title = `${props.name} - Adehot`;
  }, [props.name]);

  useEffect(() => {
    if (user && token) {
      redirectTo('/', toast.success, "Login Successfully")
    }
  }, [user, token])

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <>
      <section className="tw-min-h-screen tw-flex tw-items-stretch">
        <AuthSideImage />
        <LoginForm />
      </section>
    </>
  );
};

export default LoginPage;

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { addItemToCart } from '../../apis/requests/cart'
import { CartData } from '../../redux/slices/cartSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { Icon } from '../common/spinner'
import { ButtonSuccessMd } from '../form/button'


const ProductGrid = (props: { id: number | string | null, image: string | null, name: string | null, price: number | null, status?: string }) => {
    const [btnState, setBtnState] = useState(false)
    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state.auth.user?.id);

    const cartData: CartData = {
        id: (userId > 0 ? 0 : Math.ceil(Math.random() * 1000000)),
        userId: userId || 0,
        productId: parseInt(props?.id?.toString() || "0") || 0,
        quantity: 1,
        productPrice: props.price || 0
    }

    return (
        <div className="tw-group">
            <Link
                key={props.id}
                to={(props.id && props.name) ? `/products/${(props.name.replaceAll(' ', '-'))}/${props.id}` : "./#"}
            >
                <div className="tw-w-full tw-aspect-w-3 tw-aspect-h-2 tw-bg-gray-200 tw-rounded-lg tw-overflow-hidden xl:tw-aspect-w-7 xl:tw-aspect-h-8">
                    {props.id ?
                        <img
                            loading="lazy"
                            src={props.image || ""}
                            alt={props.name || "Skeleton Image"}
                            className={`tw-w-full tw-h-full tw-object-center tw-object-cover group-hover:tw-opacity-95 ${props.status && props.status === "loading" ? "skeleton" : ""}`}
                        /> :
                        <img
                            loading="lazy"
                            src=""
                            alt={props.name || "Loading ..."}
                            className={`tw-w-full tw-h-full tw-object-center tw-object-cover group-hover:tw-opacity-95 ${props.status && props.status === "loading" ? "skeleton" : ""}`}
                        />}
                </div>
                <h3 className={`tw-mt-4 text-product text-product-name sm:tw-text-base lg:tw-text-xl tw-text-black
                                 ${props.status && props.status === "loading" ? (!props.name ? "skeleton skeleton-title" : "skeleton") : ""}`
                }>
                    {props.name}
                </h3>
                <p className={`tw-mt-1 text-product text-product-price sm:tw-text-sm lg:tw-text-base my-1 tw-text-gray-900 ${props.status && props.status === "loading" ? (!props.price ? "skeleton skeleton-text" : "skeleton") : ""}`}>
                    {props.price ? "₦" : ""}{props.price}
                </p>
            </Link>
            {props.price && (cartData.id || userId) && <ButtonSuccessMd icon={<Icon name="" />} content='Add to cart' type='button' disabled={btnState} onClick={() => addItemToCart(cartData, dispatch, setBtnState)} />}
        </div>
    )
}

export default ProductGrid

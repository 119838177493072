import React from "react";
import IFormikError from "../../interfaces/formikError";

const Error = (props: IFormikError) => {
  return (
    <>
      {props.touched && props.error ? (
        <p className="tw-text-red-500 tw-my-1 tw-text-xs tw-italic">{props.error}</p>
      ) : null}
    </>
  );
};

export default Error;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { removeItemsFromCart } from "../apis/requests/cart";
import Cartpanel from "../components/common/cartpanel";
import { NoDataFoundError } from "../components/common/Error";
import { Button } from "../components/form/button";
import MainLayout from "../components/layouts/main";
import { routePaths } from "../config/routePaths";
import IPage from "../interfaces/page";
import { _getCartItems } from "../redux/slices/cartSlice";
import { _getProducts } from "../redux/slices/productsSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import emptyCart from "./../assets/icons/empty_cart.svg"
import warning from "./../assets/icons/warning.svg"


const CartPage: React.FunctionComponent<IPage> = (props) => {
  useEffect(() => {
    document.title = `${props.name} - Adehot`;
  }, [props.name]);

  const { data: cart, status, error } = useAppSelector(state => state.cart);
  const { user, token } = useAppSelector(state => state.auth);
  const [loaded, setLoaded] = useState(false);
  const [btnState, setBtnState] = useState(false);
  const dispatch = useAppDispatch();
  const userId = user?.id || 0;

  // const addToCart = (data: CartResponse) => (_addToCart(data))
  const { data: products, status: productStatus } = useAppSelector(state => state.products);

  useEffect(() => {
    // console.log(data, status)
    if (products.length < 1) {
      dispatch(_getProducts());
    }
  }, [dispatch, products.length])

  useEffect(() => {
    if ((status === "idle") && user && token && cart.length < 1 && products.length > 1 && productStatus !== "loading") {
      dispatch(_getCartItems());
    }
  }, [dispatch, user, status, token, loaded, cart.length, products.length, productStatus])

  useEffect(() => {
    if (!loaded && cart.length < 1 && user) dispatch(_getCartItems());
    setLoaded(true);
  }, [loaded, cart.length, dispatch, user])

  return (
    <>
      <MainLayout container hideCategory>
        <>
          {cart.length > 0 && status === "succeeded" &&
            cart.map((item) => (
              <Cartpanel
                status={status}
                cartId={item.id}
                pricetag={item.productPrice}
                productId={item.productId}
                key={item?.id || (Math.random() * 1000).toString()}
                quantity={item?.quantity}
              />
            ))
          }
        </>
        <>
          {status === "loading" && cart.length < 1 && [1, 2, 3, 4].map((n) => (
            <Cartpanel
              key={n || (Math.random() * 1000).toString()}
              cartId={0}
              productId={0}
              pricetag={null}
              quantity={0}
              status={status}
            />
          ))}
          <NoDataFoundError imageSrc={emptyCart} condition={cart.length < 1 && (status === "succeeded" || status === "idle")} errorMsg="No Item Available In Cart" />
          <NoDataFoundError imageSrc={warning} condition={status === "failed"} errorMsg={error || "Something went wrong"} />
          <>
            <div className="container cart-card py-0">
              <Link to={routePaths.products} className="mt-2 float-left btn btn-outline-success">Continue Shopping</Link>
            </div>
            {cart.length > 0 && status === "succeeded" &&
              <div className="container cart-card py-0">
                <Link to={routePaths.checkout} className="mt-2 mx-2 float-right btn btn-success">Checkout</Link>
                <Button disabled={btnState} onClick={() => removeItemsFromCart(userId, dispatch, setBtnState)} className="mt-2 float-right btn btn-outline-danger" content="Clear Cart" />
              </div>
            }
          </>
        </>
      </MainLayout>
    </>
  );
};
export default CartPage;

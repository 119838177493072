import { useState } from 'react';
import { MdDelete } from 'react-icons/md'
import { removeItemFromCart } from '../../apis/requests/cart';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import './styles.css'
const Task = (props: { cartId: number }) => {
    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state.auth.user?.id);
    const [removeBtn, setRemoveBtnState] = useState(false)
    // console.log("props.cartId", props.cartId)
    return (
        <>
            <div className="icons">
                <button disabled={removeBtn} onClick={() => { removeItemFromCart(props.cartId, (userId || 0), dispatch, setRemoveBtnState); console.log("props.cartId", props) }}>
                    <span><MdDelete className="m-auto" /> Remove</span>
                </button>
                {/* <span><MdFavorite className="m-auto" /> Favourite</span> */}
            </div>
        </>
    )
}
export default Task;
import loginFormInputFields, { ILoginFields } from "../constants/form/loginForm";
import { ButtonSuccess } from "./form/button";
import Input from "./form/input";
import Error from "./form/error";
import { useFormik, getIn } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { routePaths } from "../config/routePaths";

import { useAppDispatch, useAppSelector } from "../redux/store";
import { _signin } from "../redux/slices/authSlice";
import { useEffect, useState } from "react";
import SocialMediaAuthIcons from "./socialMediaAuthIcons";

const schema = Yup.object({
  email: Yup.string().required().email().min(5).max(50),
  password: Yup.string().required().min(8).max(15).not(["password"]),
}).required();

const LoginForm = () => {
  
  const [disable, setDisable] = useState(false);
  const dispatch = useAppDispatch();

  const {
    status
  } = useAppSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      email: "jeremiah.olisa@adehot.com",
      password: "Pa$$w0rd!",
    },
    validationSchema: schema,
    onSubmit: (values: ILoginFields) => {
      setDisable(true);
      dispatch(_signin({ values }));
    },
  });


  useEffect(() => {
    if (status === "loading") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [status]);

  

  return (
    <div className="lg:tw-w-1/2 tw-w-full tw-flex tw-items-center  tw-justify-center md:tw-px-16 tw-px-0 tw-z-0 bg-light">
      <div
        className="tw-absolute lg:tw-hidden z-10 tw-inset-0 tw-bg-gradient-to-r  tw-bg-no-repeat tw-bg-cover tw-items-center bg-light"
      >
        <div className="tw-absolute tw-inset-0 tw-z-0" />
      </div>
      <div className="tw-w-full tw-py-6 tw-z-20 bg-light">
        <div className="tw-px-4 lg:tw-px-0">
          <h1 className="tw-text-3xl tw-text-gray-900 tw-my-6">Hello,</h1>
           <p className='mb-4'>Welcome Back input your login details </p>
        </div>
        <form onSubmit={formik.handleSubmit} className=" tw-w-full tw-px-4 lg:tw-px-0">
          {loginFormInputFields.fields.map((fields, key) => {
            return (
              <div key={key} className="tw-pb-2 tw-pt-4">
                
                <Input
                  name={fields.name}
                  type={fields.type}
                  placeholder={fields.placeholder}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  touched={getIn(formik.touched, fields.name)}
                  error={getIn(formik.errors, fields.name)}
                  value={getIn(formik.values, fields.name)}
                />
                <Error
                  touched={getIn(formik.touched, fields.name)}
                  error={getIn(formik.errors, fields.name)}
                />
              </div>
            );
          })}
          <div className="tw-text-left tw-my-1 tw-text-gray-800 hover:tw-underline hover:tw-text-gray-700">
            <Link to="#">Forgot your password?</Link>
          </div>
          <div className="tw-text-left tw-my-1 tw-text-gray-900 hover:tw-underline hover:tw-text-gray-800">
            <Link to={routePaths.register}>New to Adehot? Create Account</Link>
          </div>
          <div className="tw-pb-2 tw-pt-4 d-flex justify-content-center">
            <ButtonSuccess disabled={disable} type="submit" content="sign in" />
          </div>
          <SocialMediaAuthIcons />
        </form>
      </div>
    </div>
  );
};

export default LoginForm;

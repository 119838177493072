import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

function Card({ title, image, link, index }) {
  return (
    <div className="" key={index}>
      <div className="tw-bg-white-smoke tw-rounded-lg tw-overflow-hidden tw-mx-auto">
        <div className="card_header sm:tw-text-base lg:tw-text-xl px-2 my-3">
          <h5>{title.replaceAll('-', ' ')}</h5>
        </div>
        <div>
          <img loading="lazy" src={image} alt="" className="m-auto px-2 tw-pb-1" />
          <div className="card_footer my-2">
            <p>
              <Link to={link} className="hover:tw-underline px-2 text-dark">
                View more
              </Link>
            </p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Card;

import { routePaths } from './routePaths';
import IRoute from '../interfaces/route';
import Error404Page from '../pages/404';
import AboutPage from '../pages/about';
import ConfirmEmailPage from '../pages/auth/confirmemail';
import HomePage from '../pages/home';
import LoginPage from '../pages/auth/login';
import RegisterPage from '../pages/auth/register';
import ProductPage from '../pages/product';
import ProductsPage from '../pages/products';
import CartPage from '../pages/cart';
import Profiles from '../pages/auth/profile';
import Checkout from '../pages/checkout';
import newPassword from '../components/newPassword'
import ResetPassword from '../components/resetPassword';
import Preferences from '../pages/preferences';
import Order from '../pages/order';
import { History } from '../pages/history';
import CategoryProductsPage from '../pages/categoryProducts';



const routes: IRoute[] = [
    {
        path: routePaths.home,
        name: 'Home',
        component: HomePage,
        exact: true,
        isProtected: false
    },
    {
        path: routePaths.login,
        name: 'Login',
        component: LoginPage,
        exact: true,
        isProtected: false
    },
    {
        path: routePaths.register,
        name: 'Register',
        component: RegisterPage,
        exact: true,
        isProtected: false
    },
    {
        path: routePaths.confirmEmail,
        name: 'Confirm Email',
        component: ConfirmEmailPage,
        exact: true,
        isProtected: false
    },
    {
        path: routePaths.about,
        name: 'About',
        component: AboutPage,
        exact: true,
        isProtected: false
    },
    {
        path: routePaths.error404,
        name: 'Not Found',
        component: Error404Page,
        exact: true,
        isProtected: false,
    },
    {
        path: routePaths.product,
        name: 'Product',
        component: ProductPage,
        exact: true,
        isProtected: false,
    },
    {
        path: routePaths.products,
        name: 'Products',
        component: ProductsPage,
        exact: true,
        isProtected: false,
    },
    {
        path: routePaths.products,
        name: 'Category Products',
        component: ProductsPage,
        exact: true,
        isProtected: false,
    },
    {
        path: routePaths.cart,
        name: 'Cart',
        component: CartPage,
        exact: true,
        isProtected: false
    },
    {
        path: routePaths.profile,
        name: 'profile',
        component: Profiles,
        exact: true,
        isProtected: true
    },
    {
        path: routePaths.checkout,
        name: 'checkout',
        component: Checkout,
        exact: true,
        isProtected: true
    },
    {
        path: routePaths.newPassword,
        name: 'CreatePasword',
        component: newPassword,
        exact: true,
        isProtected: true
    },
    {
        path: routePaths.resetPassword,
        name: 'ResetPassword',
        component: ResetPassword,
        exact: true,
        isProtected: true
    },
    {
        path: routePaths.preferences,
        name: 'preferences',
        component: Preferences,
        exact: true,
        isProtected: true
    },
    {
        path: routePaths.order,
        name: 'order',
        component: Order,
        exact: true,
        isProtected: false
    },
    {
        path: routePaths.orderHistory,
        name: 'history',
        component: History,
        exact: true,
        isProtected: false
    },
    {
        path: routePaths.category,
        name: 'Category Products',
        component: CategoryProductsPage,
        exact: true,
        isProtected: false
    }
]

export default routes;
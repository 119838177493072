import axios from 'axios';
// import { toast } from 'react-toastify';
// import { routePaths } from '../config/routePaths';
// import { redirectTo } from '../pages/application';
// import { history } from '../pages/application';

let { REACT_APP_ENDPOINT_PROD, REACT_APP_ENDPOINT_DEV } = process.env;

export function sleep(ms = 2000): Promise<void> {
  console.log('Kindly remember to remove `sleep`');
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? REACT_APP_ENDPOINT_DEV
      : REACT_APP_ENDPOINT_PROD,
  timeout: 50000, // 50 seconds
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (request) => {
  let auth = JSON.parse(sessionStorage.getItem('persist:root') || "{}")?.auth;
  let token = JSON.parse(auth || "{}")?.token;

  if (token) {
    api.defaults.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }

    request.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }
  }

  // console.log("token: ", token)
  // console.log("request.headers: ", request.headers)
  // console.log("api.defaults.headers: ", api.defaults.headers)
  // console.log("auth: ", auth)
  return request;
});

api.interceptors.response.use(async (response) => {
  // add artificial delay for dev env
  if (process.env.NODE_ENV === 'development') {
    await sleep();
  }
  return response;
});

// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 404) {
//       redirectTo(routePaths.error404);
//     } else if (error.response.status === 500) {
//       // redirectTo(routePaths.error404);
//       toast.error("Server Error 💥💥!")
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

export default api;

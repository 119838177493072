/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom";
import "../../assets/styles/styles.css";
import { categoryPath } from "./../../config/routePaths";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../redux/store";
import { _getCategories } from "../../redux/slices/categorySlice";



const Category = () => {
  const { data, status } = useAppSelector(state => state.categories);
  // const [categories, setCategories] = useState(data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status === "idle") {
      dispatch(_getCategories());
    }
  }, [status, dispatch])


  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="category container px-5">
      <Slider {...settings}>
        {data.map((list, i) => (
          <div className="cardx mt-3" key={i}>
            <Link
              to={categoryPath(list?.name, list?.id)}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="">
                <img loading="lazy" src={list?.imageUrl} alt="" className="img-fluid cat-img" />
              </div>
              <h6 className="category-text text-center">{list?.name}</h6>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Category;

import IFormInput, { Fields } from "../../interfaces/formInput";

export const registerFormInputFields: Fields = {
    fields: [
        // {
        //     name: "firstName",
        //     id: "firstName",
        //     placeholder: "FirstName",
        //     type: "text",
        // },
        // {
        //     name: "lastName",
        //     id: "lastName",
        //     placeholder: "LastName",
        //     type: "text",
        // },
        {
            name: "email",
            id: "email",
            placeholder: "Email",
            type: "email",
        },
        // {
        //     name: "phoneNumber",
        //     id: "phoneNumber",
        //     placeholder: "Phone Number (+234**********)",
        //     type: "tel",
        // },
        // {
        //     name: "dateOfBirth",
        //     id: "dateOfBirth",
        //     placeholder: "Date Of Birth",
        //     type: "date",
        // },
        {
            name: "password",
            id: "password",
            placeholder: "Password",
            type: "password",
        },
        // {
        //     name: "confirmPassword",
        //     id: "confirmPassword",
        //     placeholder: "Confirm Password",
        //     type: "password",
        // }
    ] as Array<IFormInput>,
}

export interface IRegisterFields {
    email: string,
    // lastName: string,
    // firstName: string,
    password: string,
    // confirmPassword: string,
    // phoneNumber: string,
    // dateOfBirth: Date,
}
export default registerFormInputFields;


import Card from "../../components/common/Card";
import secondcat from "../../constants/data/secondcat";

function Sectioner() {
  return (
    <div className="container">
      <div className="container-fluid" id="sectioner">
        <div className="tw-mx-auto tw-grid tw-grid-cols-2 tw-gap-y-10 tw-gap-x-3 sm:tw-grid-cols-2 sm:tw-gap-x-6 md:tw-grid-cols-3 md:tw-gap-x-4 lg:tw-grid-cols-4 xl:tw-grid-cols-4 xl:tw-gap-x-8" id="">
          {secondcat.map((cat, i) => (
            <Card
              key={i}
              // className="col-lg-3 col-md-3 col-sm-6"
              index={i}
              title={cat.name}
              image={cat.image}
              link={cat.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sectioner;

import { toast } from "react-toastify";
import { CartData, addToCart as _addToCart, increaseQuantity, removeFromCart, clearCart, decreaseQuantity, updateQuantity } from "../../redux/slices/cartSlice";
import { catchAsync } from "../../utils/ErrorHandler";
import api from "../axios";
// import { getProduct } from "./products";

export type CartResponse = {
    id: number;
    userId: number;
    productId: number;
    quantity: number;
}

export type OrderCreationDto = {
    id: number;
    userId: number;
    statusId: number;
    orderReference: string;
    totalOrderPrice: number;
    paymentType: string;
    deliveryType: string;
    cartItems: CartData[];
}

export const addToCart = (values: CartResponse) => {
    return catchAsync(() =>
        async () => {
            const response = await api({
                method: "POST",
                url: "Cart",
                data: values,
            });
            return response.data;
        }
    )();
}

export const increaseCartQty = (cartId: string | number) => {
    return catchAsync(() =>
        async () => {
            const response = await api({
                method: "PATCH",
                url: `Cart/${cartId}/Increase`,
            });
            return response.data;
        }
    )();
}

export const decreaseCartQty = (cartId: string | number) => {
    return catchAsync(() =>
        async () => {
            const response = await api({
                method: "PATCH",
                url: `Cart/${cartId}/Decrease`,
            });
            return response.data;
        }
    )();
}

export const updateCartQty = (cartId: string | number, quantity: number) => {
    return catchAsync(() =>
        async () => {
            const response = await api({
                method: "PATCH",
                url: `Cart/${cartId}`,
                data: quantity,
            });
            return response.data;
        }
    )();
}

export const getCartItems = () => {
    return catchAsync(() =>
        async () => {
            const response = await api.get("Cart");
            return response.data;
        }
    )();
}

export const getCartItem = (cartId: string | number) => {
    return catchAsync(() =>
        async () => {
            const response = await api.get(`Cart/${cartId}`);
            return response.data;
        }
    )();
}

export const deleteCartItems = () => {
    return catchAsync(() =>
        async () => {
            const response = await api.delete(`Cart`);
            return response.data;
        }
    )();
}

export const deleteCartItem = (cartId: string | number) => {
    return catchAsync(() =>
        async () => {
            const response = await api.delete(`Cart/${cartId}`);
            return response.data;
        }
    )();
}

export const checkout = (data: OrderCreationDto) => {
    return catchAsync(() =>
        async () => {
            const response = await api({
                method: "POST",
                url: `Cart/Checkout`,
                data,
            });
            return response.data;
        }
    )();
}

export const addItemToCart = async (cartRes: CartData, dispatch: any, setBtnState: any) => {
    if (cartRes.quantity > 0 && cartRes.productId > 0 && cartRes.productPrice > 0) {
        setBtnState(true)
        let cartData: CartResponse = {
            id: cartRes.id,
            productId: cartRes.productId,
            quantity: cartRes.quantity || 1,
            userId: cartRes.userId || 0,
        }

        if (cartData.userId && cartData.userId > 0) {
            let response = await addToCart(cartData)
            // console.log(response)
            if (response && response.isSuccessful === true && response.data) {
                cartRes.id = response.data?.id
                // toast.success(response.message)
            } else {
                toast.error(response.message || "Item not added to cart")
            }
        }

        let dispatchRes = dispatch(_addToCart(cartRes))

        if (!dispatchRes.error) {
            toast.success("Item added to cart successfully")
        }
        setBtnState(false)
    }
}

export const removeItemFromCart = async (cartId: number, userId: number, dispatch: any, setBtnState: any) => {
    setBtnState(true)
    if (userId > 0) {
        let response = await deleteCartItem(cartId)
        // console.log(response)
        if (response && response.isSuccessful === true && response.data) {
            // cartData.id = response.data?.id
            // toast.success(response.message)
        } else {
            toast.error(response.message || "Item not added to cart")
        }
    }

    let dispatchRes = dispatch(removeFromCart(cartId))

    if (!dispatchRes.error) {
        // toast.success("Item added to cart successfully")
    }
    setBtnState(false)
}

export const removeItemsFromCart = async (userId: number, dispatch: any, setBtnState: any) => {
    setBtnState(true)
    if (userId > 0) {
        let response = await deleteCartItems()
        // console.log(response)
        if (response && response.isSuccessful === true && response.data) {
            // cartData.id = response.data?.id
            // toast.success(response.message)
        } else {
            toast.error(response.message || "Item not added to cart")
        }
    }

    let dispatchRes = dispatch(clearCart())

    if (!dispatchRes.error) {
        toast.success("Cart Cleared")
    }
    setBtnState(false)
}

export const IncreaseCartQty = async (cartId: number, userId: number, dispatch: any, setBtnState: any) => {
    setBtnState(true)
    if (userId > 0) {
        let response = await increaseCartQty(cartId)
        // console.log(response)
        if (response && response.isSuccessful === true && response.data) {
            // cartData.id = response.data?.id
            // toast.success(response.message)
        } else {
            toast.error(response.message || "Item not added to cart")
        }
    }

    let dispatchRes = dispatch(increaseQuantity({ cartId }))

    if (!dispatchRes.error) {
        toast.success("Cart Cleared")
    }
    setBtnState(false)
}

export const DecreaseCartQty = async (cartId: number, userId: number, dispatch: any, setBtnState: any) => {
    setBtnState(true)
    if (userId > 0) {
        let response = await increaseCartQty(cartId)
        // console.log(response)
        if (response && response.isSuccessful === true && response.data) {
            // cartData.id = response.data?.id
            // toast.success(response.message)
        } else {
            toast.error(response.message || "Item not added to cart")
        }
    }

    let dispatchRes = dispatch(decreaseQuantity({ cartId }))

    if (!dispatchRes.error) {
        toast.success("Cart Cleared")
    }
    setBtnState(false)
}

export const UpdateCartQty = async (cartId: number, userId: number, quantity: number, dispatch: any, setBtnState: any) => {
    setBtnState(true)
    if (userId > 0) {
        let response = await increaseCartQty(cartId)
        // console.log(response)
        if (response && response.isSuccessful === true && response.data) {
            // cartData.id = response.data?.id
            // toast.success(response.message)
        } else {
            toast.error(response.message || "Item not added to cart")
        }
    }

    let dispatchRes = dispatch(updateQuantity({ cartId, quantity }))

    if (!dispatchRes.error) {
        toast.success("Cart Updated")
    }
    setBtnState(false)
}

export const Checkout = async (data: OrderCreationDto, dispatch: any, setBtnState: any) => {
    setBtnState(true)
    if (data.userId > 0) {
        let response = await checkout(data)
        console.log(response)
        if (response && response.isSuccessful === true && response.data) {
            // cartData.id = response.data?.id
            console.log("response", response)
            toast.success("Order Successful")
        } else {
            toast.error(response?.message || "Item not added to cart")
        }
    }

    dispatch(clearCart())
    setBtnState(false)
}

import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import categoryReducer from "./slices/categorySlice";
import productsReducer from "./slices/productsSlice";
import productReducer from "./slices/productSlice";
import cartReducer from "./slices/cartSlice";
import categoryProductsReducer from "./slices/categoryProductsSlice";

const rootReducer = combineReducers({
    auth: authReducer,
    categories: categoryReducer,
    products: productsReducer,
    product: productReducer,
    categoryProducts: categoryProductsReducer,
    cart: cartReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

import { useState } from "react";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { MdArrowBack } from "react-icons/md";
import { Link } from "react-router-dom";
import { addItemToCart } from "../../apis/requests/cart";
import { CartData } from "../../redux/slices/cartSlice";
import { SingleProductData } from "../../redux/slices/productSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import PaymentComp from "../buynow/PaymentComp";
import OtherProductImages from "./../otherProductImages";

const iconstyle = { color: "#FBC218", fontSize: "1.2em" };


const Rating = (ratings?: number | string) => {
    const rating: Array<JSX.Element> = [];
    for (let index = 1; index <= 3; index++) {
        let ratings2 = ((ratings as number) || 3) - index;
        if (ratings2 < 0) {
            rating.push(<BsStarHalf key={index} style={iconstyle} />);
            break;
        } else {
            rating.push(<BsStarFill key={index} style={iconstyle} />);
        }
    }

    return rating;
}



const ProductPanel = ({ product, status }: { product?: SingleProductData, status: string }) => {
    const [proceedToPay, setproceedToPay] = useState(false);
    const userId = useAppSelector(state => state.auth.user?.id);
    const [btnState, setBtnState] = useState(false);
    const dispatch = useAppDispatch();


    const toggleNext = () => {
        setproceedToPay((prev) => !prev);
    };

    const [defaultaddress, setdefaultaddress] = useState(true);
    const [buyforsomeone, setbuyforsomeone] = useState(false);

    const toggleAdd = () => {
        setdefaultaddress((prev) => !prev);
        setbuyforsomeone(false)
    };

    const toggleBuy = () => {
        setbuyforsomeone((prev) => !prev);
        setdefaultaddress(false)
    };

    let productPrice = (product?.price ? (parseFloat(product?.price) || 0) : 0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const addToCartData: CartData = {
        id: (userId > 0 ? 0 : Math.ceil(Math.random() * 1000000)),
        userId: userId || 0,
        productId: product?.id || 0,
        quantity: 1,
        productPrice: productPrice || 0
    }

    return (
        <>
            <div className={`col-md-6 col-lg-6`}>
                <div className={`card border-0 ml-0 mr-0 w-100 p-0`}>
                    <img
                        loading="lazy"
                        src={product?.images[0]?.imageUrl}
                        alt={product?.name}
                        className={`img-fluid mx-auto mb-0 ${status === "loading" ? "skeleton" : ""}`}
                    />
                </div>

                <OtherProductImages images={product?.images?.slice(1)} />
            </div>
            <div className="col-md-6 col-lg-6 desc">
                <div className="">
                    <h3 className={status === "loading" && !product?.name ? "skeleton skeleton-title mt-md-5" : ""} id="product_title">{product?.name}</h3>
                    <h6 className={status === "loading" && !product?.description ? "skeleton skeleton-title" : ""} id="product_desc">{product?.description}</h6>
                    {product?.extras && <p>{product?.extras}</p>}
                </div>
                <div className="d-flex align-items-center mt-4">
                    <div className="price_container mr-4">
                        <b>₦{product?.price || "0000"}</b>
                    </div>
                    <div className={`d-flex justify-around ${status === "loading" && !product?.rating ? "skeleton skeleton-text" : ""}`}>
                        {Rating(product?.rating) || ""}
                    </div>
                </div>
                <div className="btn_holder mt-5">
                    <p>
                        <Link to="/">click here to view our return policy</Link>
                    </p>
                    <div className="mt-4">
                        <button onClick={async () => await addItemToCart(addToCartData, dispatch, setBtnState)} disabled={btnState} className="btn btn-success btn-lg px-3 mr-2 mr-md-4 mr-lg-4">
                            Add to Cart
                        </button>
                        <button
                            className="btn btn-success btn-lg"
                            data-toggle="modal"
                            data-target="#buynowmodal"
                        >
                            Buy Now
                        </button>
                    </div>
                </div>
                {/*buy now  Modal begins */}
                <div
                    className="modal fade"
                    tabIndex={-1}
                    id="buynowmodal"
                    role="dialog"
                    aria-labelledby="buynowmodalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content buynowmodal">
                            <div className="modal-body pt-5">
                                <div className="container px-md-3 px-lg-3">
                                    <div className="d-flex justify-content-between mb-4">
                                        {proceedToPay ? (
                                            <>
                                                <button
                                                    className="btn btn-light"
                                                    onClick={(e) => toggleNext()}
                                                >
                                                    <MdArrowBack style={{ fontSize: '2em' }} />
                                                </button>
                                                <div className="bg-light p-3">
                                                    <h6 className="text-success">Payment</h6>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="bg-light p-3">
                                                    <h4 className="text-success">Shipping</h4>
                                                </div>
                                                <h6 className="text-success">Payment</h6>
                                            </>
                                        )}
                                    </div>
                                    <form
                                        action=""
                                        className="form-group"
                                        onSubmit={(e) => e.preventDefault()}
                                    >
                                        {!proceedToPay ? (
                                            <>
                                                <div className="d-flex align-items-center bg-light p-2 my-3" onClick={e => toggleAdd()}>
                                                    <input
                                                        type="checkbox"
                                                        checked={defaultaddress}
                                                        onChange={(e) => toggleAdd()}
                                                        className="form-check-input mr-2"
                                                    />
                                                    <label
                                                        htmlFor=""
                                                        className="text-muted form-check-label"
                                                    >
                                                        Default address
                                                    </label>
                                                </div>
                                                <div className="d-flex align-items-center bg-light p-2 my-3" onClick={e => toggleBuy()}>
                                                    <input
                                                        type="checkbox"
                                                        checked={buyforsomeone}
                                                        onChange={(e) => toggleBuy()}
                                                        className="form-check-input mr-2"
                                                    />
                                                    <label
                                                        htmlFor=""
                                                        className="text-muted form-check-label"
                                                    >
                                                        Buy for someone
                                                    </label>
                                                </div>
                                                {buyforsomeone ? (
                                                    <>
                                                        <div className=" p-2">
                                                            <label htmlFor="" className="text-muted">
                                                                state
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control  mb-2 bg-light"
                                                            />
                                                        </div>
                                                        <div className=" p-2">
                                                            <label htmlFor="" className="text-muted">
                                                                address
                                                            </label>
                                                            <textarea className="form-control  mb-2 bg-light"></textarea>
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                <div className=" p-2">
                                                    <label htmlFor="" className="text-muted">
                                                        Delivery Note
                                                    </label>
                                                    <textarea className="form-control  mb-2 bg-light"></textarea>
                                                </div>
                                                <button
                                                    className="btn btn-success btn-lg float-right mt-1"
                                                    onClick={(e) => toggleNext()}
                                                >
                                                    Continue
                                                </button>
                                            </>
                                        ) : (
                                            <PaymentComp />
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*buy now  Modal ends */}
            </div>
        </>
    )
}

export default ProductPanel

import Footer from '../components/common/Footer';
import Header from '../components/common/Header/Header';
import Orderpanel from '../components/common/orderPanel';
const Order = () => {
    return (
        <>
            {/* headers secton */}
            <Header/>


            {/* main orders page */}
            <div className="container mt-5">    
                <h3 className="text-muted">Order</h3>


                <div className="row">


                  

                    <div className="col-md-12 col-lg-12 col-sm-12">

                        <Orderpanel
                        name='Virtual Reality'
                        price='N400'
                        status='delivered'
                        qty='1'

                        />

                       

                    </div>


                </div>

            </div>

            {/* for part */}
            <Footer className="mt-5"/>
        </>
    )
}

export default Order;
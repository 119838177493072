import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../apis/axios";
import { routePaths } from "../config/routePaths";
import { redirectTo } from "../pages/application";
import { ErrorHandler } from "../utils/ErrorHandler";
import SocialMediaAuthIcons from "./socialMediaAuthIcons";

type IConfirmEmailMessage = {
  userId: string;
  token: string;
};

const ConfirmEmailMessage = (props: IConfirmEmailMessage) => {
  const [userId, setUserId] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [isVerifyingToken, setIsVerifyingToken] = useState<Boolean>(true);

  const _verifyToken = async (data: IConfirmEmailMessage) => {
    setIsVerifyingToken(true);

    try {
      const response = await api({
        method: "get",
        url: `Account/ConfirmEmail?userId=${data.userId}&token=${data.token}`,
      });

      setIsVerifyingToken(false);

      const {
        data: { isSuccessful, message },
      } = response;

      if (isSuccessful) {
        redirectTo(routePaths.login, toast.success, message);
      } else {
        toast.error(message);
      }

      // console.log(response.data);
    } catch (e: any) {
      setIsVerifyingToken(false);
      // console.log(e);
      const err = ErrorHandler(e);
      // console.log(err);

      if (typeof err === "object") {
        err?.forEach((error: string) => {
          toast.error(error);
        });
      } else {
        toast.error(err);
      }
    }
  };

  useEffect(() => {
    // logging.info(`Loading ${props.name}`);
    let { userId, token } = props;

    setUserId(userId);
    setToken(token);
  }, [props]);

  useEffect(() => {
    if (userId && token) _verifyToken({ userId, token });
  }, [userId, token]);

  return (
    <div className="lg:tw-w-1/2 tw-w-full tw-flex tw-items-center tw-bg-gray-200 tw-justify-center md:tw-px-16 tw-px-0 tw-z-0">
      <div
        className="tw-absolute lg:tw-hidden tw-z-10 tw-inset-0 tw-bg-gradient-to-r tw-from-deep-sea-green tw-to-bush tw-bg-no-repeat tw-bg-cover tw-items-center"
        style={{
          backgroundImage:
            "url(https://images.unsplash.com/photo-1577495508048-b635879837f1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80)",
        }}
      >
        <div className="tw-absolute tw-bg-bush tw-opacity-60 tw-inset-0 tw-z-0" />
      </div>
      <div className="tw-w-full tw-py-6 tw-z-20">
        <div
          className={
            isVerifyingToken
              ? "tw-flex tw-items-center tw-justify-center tw-space-x-2"
              : "tw-hidden"
          }
        >
          <div className="tw-w-8 tw-h-8 tw-bg-blue-400 tw-rounded-full tw-animate-pulse"></div>
          <div className="tw-w-8 tw-h-8 tw-bg-green-400 tw-rounded-full tw-animate-bounce"></div>
          <div className="tw-w-8 tw-h-8 tw-bg-black tw-rounded-full tw-animate-pulse"></div>
        </div>

        <div className="tw-w-full tw-px-4 lg:tw-px-0">
          <SocialMediaAuthIcons />
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmailMessage;

import React from "react";

function Search() {
  return (
    <div>
      <form action="" className="form-group mb-0">
        {/*<div className="input-group">
          <div className="search-pre-sqr search-pre-sqr-border-left tw-bg-platinum">
            ₦
          </div>
          <input
            type="text"
            className="form-control form-control-sm"
            aria-label="Search Product"
          />

          <button className="search-pre-sqr search-pre-sqr-border-right tw-bg-adehot tw-text-white">
            <i className="fa fa-search"></i>
          </button>
  </div>*/}
        <div className="input-group mt-2">
          <div className="input-group-prepend d-sm-none d-none d-md-block">
            <span className="input-group-text">₦</span>
          </div>
          <input type="text" className="form-control" />
          <button
            type="submit"
            className="btn  btn-success input-group-append d-flex justify-content-center align-items-center"
          >
            <i className="fa fa-search"></i>
          </button>
        </div>
      </form>
    </div>
  );
}

export default Search;

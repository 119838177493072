const secondcat = [{
        name: "Wristwatches",
        image: require("../../assets/images/watch.png").default,
        link: "/wristwatches"

    },
    {
        name: "Basic Gadgets",
        image: require("../../assets/images/oculus.png").default,
        link: "/basic-adgets"

    },
    {
        name: "Properties",
        image: require("../../assets/images/chair.png").default,
        link: "/properties"

    },
    {
        name: "Feel-the-Fashion",
        image: require("../../assets/images/fashionap.png").default,
        link: "/Feel-the-Fashion"

    },
    {
        name: "tv-screen",
        image: require("../../assets/images/tv.png").default,
        link: "/tv-screen"

    },
    {
        name: "beauty-products",
        image: require("../../assets/images/makeup.png").default,
        link: "/beauty-products"

    },
    {
        name: "toys",
        image: require("../../assets/images/toyz.png").default,
        link: "/toys"

    },
    {
        name: "cars",
        image: require("../../assets/images/tv.png").default,
        link: "/cars"

    },

]

export default secondcat;
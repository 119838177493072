import React, { useEffect } from "react";
import {
  //   BrowserRouter,
  Route,
  Switch,
  RouteComponentProps,
  Router,
  Redirect,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
// import logging from "../config/logging";

import routes from "../config/router";
import Error404Page from "./404";


import "react-toastify/dist/ReactToastify.min.css";

import { createBrowserHistory } from "history";
import { useAppSelector } from "../redux/store";
import { routePaths } from "../config/routePaths";

export const history = createBrowserHistory();
export const redirectTo = (to: string, toast?: any, message?: string) => {
  if (toast && message) toast(message)
  history.push(to)
  return <Redirect to={to} />
};

const Application: React.FunctionComponent<{}> = (props) => {
  
  useEffect(() => {
    document.title = "Adehot";
    // logging.info("Loading application.");
  }, []);
  
  
  const { user, token } = useAppSelector((state) => state.auth);
  // useEffect(() => {
  //   if (user && token) {
  //     redirectTo('/', toast.success, "Login Successfull")
  //   }
  // }, [user, token])

  // let isProtected = false;
  // let isAuth = true; // user && token
  // let result = (isProtected && isAuth) || !isProtected;
  // console.log(`${result ? "show component" : "redirect to login"}`)

  const RedirectToLogin = () => redirectTo(routePaths.login, toast.error, "UnAuthorised Access, Please Login!");
  return (
    <div>
      <Router history={history}>
        <Switch>
          {routes.map((route, index) => {
            // document.title = route.name;
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={
                  ((route.isProtected && user && token) || !route.isProtected) ?
                    (props: RouteComponentProps<any>) => (
                      <route.component
                        name={route.name}
                        {...props}
                        {...route.props}
                      />
                    )
                    :
                    RedirectToLogin
                }
              />
            );
          })}
          <Route component={Error404Page} />
        </Switch>
        <ToastContainer />
        {/* <AllRoutes /> */}
      </Router>
    </div>
  );
};

export default Application;

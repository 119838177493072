import SocialIcons from "./socialIcons";
import shopper from "../assets/images/shopper.png"

const AuthSideImage = () => {
  return (
    <div className="lg:tw-flex md:tw-flex tw-w-1/2 tw-hidden tw-bg-gradient-to-r  tw-bg-no-repeat tw-bg-cover tw-relative tw-items-center">
       <div className="ellipse">
         <img src={shopper} alt="a happy shopper" id="shopper" />
       </div>
      <div className="tw-bottom-0 tw-absolute tw-p-4 tw-text-center tw-right-0 tw-left-0 tw-flex tw-justify-center tw-space-x-4">
        <SocialIcons />
      </div>
    </div>
  );
};

export default AuthSideImage;

import Footer from "../common/Footer";
import Header from "../common/Header/Header";
import Category from "../home/Category";
import styled from "styled-components";

type Props = {
  props?: any;
  children?: React.ReactChild | React.ReactChild[];
  container?: true | false;
  hideCategory?: true | false;
};

const Container = styled.div<{ container?: true | false; }>`
  padding: ${p => p.container ? "0px 45px" : null};
`;

const MainLayout: React.FC<Props> = (props: Props) => {
  return (
    <>
      <main className="tw-bg-white-smoke tw-font-poppins">
        <Header />
        {(props.hideCategory) ? null : <Category />}
        <Container container={props.container}>
          {props.children}
        </Container>
        <Footer />
      </main>
    </>
  );
};

export default MainLayout;

/* eslint-disable no-unreachable */
import { useEffect, useState } from 'react';
import MainLayout from '../components/layouts/main';
import '../assets/styles/styles.css';
import { AiOutlineHome } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';
import { MdArrowBack } from 'react-icons/md';
import ChangeAddress from '../components/common/changeAddress'
import { useAppDispatch, useAppSelector } from '../redux/store';
import { _getCartItems } from '../redux/slices/cartSlice';
import { _getProducts } from '../redux/slices/productsSlice';
import { NoDataFoundError } from '../components/common/Error';
import emptyCart from "./../assets/icons/empty_cart.svg"
import warning from "./../assets/icons/warning.svg"
import PaymentComp from '../components/buynow/PaymentComp';

const Checkout = () => {
  const { data: cart, status, error } = useAppSelector(state => state.cart);
  const { user, token } = useAppSelector(state => state.auth);
  const [loaded, setLoaded] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const { data: products, status: productStatus } = useAppSelector(state => state.products);
  const dispatch = useAppDispatch();
  // const userId = user?.id || 0;
  const [steps, setsteps] = useState(1);

  const [address, setAddress] = useState(null);
  const [email, setEmail] = useState(null);

  // const addToCart = (data: CartResponse) => (_addToCart(data))


  useEffect(() => {
    // console.log(data, status)
    if (products.length < 1) {
      dispatch(_getProducts());
    }
  }, [dispatch, products.length])

  useEffect(() => {
    if ((status === "idle") && user && token && cart.length < 1 && products.length > 1 && productStatus !== "loading") {
      dispatch(_getCartItems());
    }
  }, [dispatch, user, status, token, loaded, cart.length, products.length, productStatus])

  useEffect(() => {
    if (!loaded && cart.length < 1 && user) dispatch(_getCartItems());
    setLoaded(true);
  }, [loaded, cart.length, dispatch, user])

  useEffect(() => {
    setAddress(user?.userAddress)
    setEmail(user?.email)
  }, [user])
  // const [payment, setpayment] = useState(1);


  const onDeliveryMethodCheck = (deliveryMethod: any) => {
    setDeliveryMethod(deliveryMethod);
  };


  const incrementSteps = () => {
    setsteps((prev) => prev + 1);
  };

  const decrementsteps = () => {
    if (steps > 1) {
      setsteps((prev) => prev - 1);
    }
  };

  const AddressComp = () => {
    return (
      <>
        <h6>
          <strong>Address</strong>
        </h6>
        <div className="address_con px-4">
          <div className="d-flex  align-items-center">
            <div className="small_con mr-2 mb-3 rounded">
              <AiOutlineHome size={20} />
            </div>
            <p>{address || "Address not set"}</p>
          </div>
          <div className="d-flex  align-items-center">
            <div className="small_con mr-2" >
              <AiOutlineMail size={22} />
            </div>
            <p>{email || "Email not set"}</p>
          </div>
        </div>
        <div className="mt-4 row">
          <div className="col-md-6 my-md-1">
            <button
              className="btn btn-success "
              id="changeaddress"

              onClick={e => setOpen(true)}
            >
              Change Address
            </button>
          </div>
          <div className="col-md-6 my-md-1">
            <button
              className="btn btn-success float-right"
              onClick={() => incrementSteps()}
            >
              Continue
            </button>
          </div>
        </div>
      </>
    );
  };

  const DeliveryMethodComp = () => {
    return (
      <>


        <h6>
          <strong>Delivery Method</strong>
        </h6>


        <div className="address_con pl-3">
          <div className="d-flex  align-items-center form-check mb-5 pl-5">
            <input
              name="deliveryMethod"
              type="radio"
              checked={deliveryMethod === "Pick Up"}
              value="Pick Up"
              onChange={() => onDeliveryMethodCheck("Pick Up")}
            />
            {/* <input type="checkbox" className="form-check-input" name="" id="" /> */}
            <div className="form-check-label pl-3">
              <h6 className='text-muted'>
                <strong>Pick Up</strong>
              </h6>
              <p>Km 45, Ahmadu Bello way Victoria Island, Lagos</p>
              <button className='text-success'> <b>Change</b> </button>
            </div>
          </div>

          <div className="d-flex  align-items-center form-check pl-5">
            <input
              name="deliveryMethod"
              type="radio"
              checked={deliveryMethod === "Door Delivery"}
              disabled={address === null}
              value="Door Delivery"
              onChange={() => onDeliveryMethodCheck("Door Delivery")}
            />
            {/* <input type="checkbox" className="form-check-input" name="" id="" /> */}
            <div className="form-check-label pl-3">
              <h6 className='text-muted'>
                <strong>Door Delivery</strong>
              </h6>
              <p>{address || "Address not set"}</p>
              <button onClick={() => setOpen(true)} className='text-success'> <b>Change</b> </button>
            </div>
          </div>
        </div>
        <div className="mt-4 row">
          <div className="col-md-6 my-md-1">
            <button className="btn" onClick={() => decrementsteps()}>
              <MdArrowBack size={30} />
            </button>
          </div>
          <div className="col-md-6 my-md-1">
            <button
              className="btn btn-success float-right"
              onClick={() => incrementSteps()}
            >
              Continue
            </button>
          </div>
        </div>
      </>
    );
  };

  const PaymentMethodComp = () => {
    return (
      <>
        <PaymentComp address={address} email={email} deliveryMethod={deliveryMethod} />
        <div className="mt-4 row">
          <button className="btn" onClick={() => decrementsteps()}>
            <MdArrowBack size={30} />
          </button>
          {/* <button className="btn btn-success btn-lg px-5 py-2">Checkout</button> */}
        </div>
      </>
    );
  };

  const RenderStep = () => {
    switch (steps) {
      case 1:
        return <AddressComp />;
        break;
      case 2:
        return <DeliveryMethodComp />;
        break;
      case 3:
        return <PaymentMethodComp />;
        break;

      default:
        return <AddressComp />;
        break;
    }
  };

  const [open, setOpen] = useState(false);
  //declear style for modal
  const styles = {
    display: (open === true ? 'block' : 'none')
  }
  return (

    <MainLayout hideCategory>
      <div className="container mt-4">
        <div style={styles}>
          <ChangeAddress setOpen={setOpen} address={address} setAddress={setAddress} />
        </div>
        <div className="container mt-5">
          <h4 id="settings_title">Checkout</h4>
          <div className="row mt-4">
            <div className="col-md-5 checkout-item-box px-0 tw-overflow-y-auto tw-h-screen">
              {products.length > 0 && cart.length > 0 && status === "succeeded" &&
                cart.map((item) => {
                  let product = products[products?.findIndex((product) => { return product.id === item.productId; })]
                  // let product = products[productIndex]
                  return (
                    <div key={item.id} className="" id="checkout_img_container">
                      <img loading="lazy" src={product?.image || ""} alt="" className="mx-auto" id="checkout_img" />
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <h4 id="checkout_tag">{product?.name || "Unknown Product"}</h4>
                        <div className="">
                          <p>Qty : {item?.quantity}</p>
                          <p>total: &#8358; {item?.productPrice}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              <NoDataFoundError imageSrc={emptyCart} condition={cart.length < 1 && (status === "succeeded" || status === "idle")} errorMsg="No Item Available In Cart" />
              <NoDataFoundError imageSrc={warning} condition={status === "failed"} errorMsg={error || "Something went wrong"} />

            </div>
            <div className="col-md-7 mt-5">
              <div className="levels mb-3">
                <div className="level_indicator">
                  <div className="level_counter mr-1">
                    <h2>1</h2>
                  </div>
                  <p>Address Details</p>
                </div>
                <div className="level_indicator">
                  <div className="level_counter mr-1">
                    <h2>2</h2>
                  </div>
                  <p>Delivery Method</p>
                </div>
                <div className="level_indicator">
                  <div className="level_counter mr-1">
                    <h2>3</h2>
                  </div>
                  <p>Payment Method</p>
                </div>
              </div>
              <RenderStep />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Checkout;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../apis/axios";
import { ErrorHandler } from "../../utils/ErrorHandler";

type CategoryData = {
    name: string;
    imageUrl: string,
    id: string
}

type CategorySlice = {
    status: "idle" | "loading" | "succeeded" | "failed";
    error: string | null;
    data: Array<CategoryData>
}

export const _getCategories: any = createAsyncThunk<any, { rejectValue: any }>(
    "Category/GetAll",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await api.get("/Category");
            return { response };
        } catch (err: any) {
            const error = ErrorHandler(err);
            return rejectWithValue(error || "unable to load category");
        }
    }
);

const categorySlice = createSlice({
    name: "categories",
    initialState: {
        status: "idle",
        error: null,
        data: []
    } as CategorySlice,
    reducers: {},
    extraReducers: {
        [_getCategories.pending]: (
            state: CategorySlice
        ) => {
            state.status = "loading";
            state.error = null;
            state.data = [];
        },
        [_getCategories.fulfilled]: (
            state: CategorySlice,
            action: PayloadAction<any>
        ) => {
            state.status = "succeeded";
            state.data = action.payload.response.data.data;
            state.error = null;
        },
        [_getCategories.rejected]: (
            state: CategorySlice,
            action: PayloadAction<any>
        ) => {
            state.status = "failed";
            state.error = action.payload;
            state.data = [];
        },
    }
})

export const getCategoryState = (state: CategorySlice) => state?.data;
export default categorySlice.reducer;
// export const  { }  = categorySlice.actions;
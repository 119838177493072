import { catchAsync } from "../../utils/ErrorHandler";
import api from "../axios";

export const getProducts: any = async () => {
    return catchAsync(() => 
        async () => {
            const response = await api.get(`/Products/`);
            return response.data;
        }
    )();
};

export const getCategoryProducts: any = async (categoryId: number) => {
    return catchAsync(() => 
        async () => {
            const response = await api.get(`/Products/${categoryId}/Category`);
            return response.data;
        }
    )();
};

export const getProduct: any = async (productId: number) => {
    return catchAsync(() => 
        async () => {
            const response = await api.get(`/Products/${productId}`);
            return response.data;
        }
    )();
};
import ngn from "../../../assets/images/ngn.svg";

type Props = {
  className?: string;
}
function Country(props: Props) {
  return (
    <div className={props.className}>
      <div className={"dropdown ml-5 mt-2"}>
        <div
          className="flag-container"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div
            style={{ backgroundImage: `url(${ngn})` }}
            className="dropdown-toggle flag"
          ></div>
        </div>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href="/">
            Ghana
          </a>
          <a className="dropdown-item" href="/">
            South africa
          </a>
          <a className="dropdown-item" href="/">
            Tanzania
          </a>
        </div>
      </div>
    </div>
  );
}

export default Country;

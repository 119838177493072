import SocialIcons from "./socialIcons";

const SocialMediaAuthIcons = () => {
  return (
    <div className="tw-p-4 tw-text-center tw-right-0 tw-left-0 tw-flex tw-justify-center tw-space-x-4 tw-mt-16 lg:tw-hidden ">
      <SocialIcons />
    </div>
  );
};

export default SocialMediaAuthIcons;

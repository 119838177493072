import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ProductGrid from '../components/commerce/productGrid';
import { NoDataFoundError } from '../components/common/Error';
import MainLayout from '../components/layouts/main';
import IPage from '../interfaces/page';
import { _getCategoryProducts } from '../redux/slices/categoryProductsSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { toTitleCase } from '../utils/DataType';
import taken from "./../assets/icons/taken.svg"
import warning from "./../assets/icons/warning.svg"

// import { useAppDispatch } from '../redux/store';

const CategoryProductsPage: React.FunctionComponent<IPage & RouteComponentProps<any>> = (props) => {
  // eslint-disable-next-line no-extend-native

  const categoryId = props.match.params.categoryId;
  const category = toTitleCase(props.match.params.slug?.replaceAll('-', ' ') || "Category");
  const { data, status, error } = useAppSelector(state => state.categoryProducts);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // console.log(data, status)
    if (status === "idle") {
      dispatch(_getCategoryProducts(categoryId));
    }
  }, [status, dispatch, data, categoryId])

  // let data = [] as Array<any>;
  return (
    <>
      <MainLayout>
        <div className="tw-max-w-2xl tw-mx-auto tw-py-16 tw-px-4 sm:tw-py-24 sm:tw-px-6 lg:tw-max-w-7xl lg:tw-px-2">
          <h3 className={`tw-mt-4 text-product text-product-name mb-4 tw-text-xl lg:tw-text-3xl tw-text-black`}>{category}</h3>
          <div className="tw-grid tw-grid-cols-2 tw-gap-y-10 tw-gap-x-3 sm:tw-grid-cols-2 sm:tw-gap-x-6 md:tw-grid-cols-3 md:tw-gap-x-4 lg:tw-grid-cols-4 xl:tw-grid-cols-4 xl:tw-gap-x-8">
            {data.length > 0 && status === "succeeded" &&
              data.map((product) => (
                <ProductGrid key={product.id} id={product.id} image={product.image} name={product.name} price={product.price} />
              ))}

            {status === "loading" && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n) => (<ProductGrid key={n} id={null} image={null} name={null} price={null} status={status} />))}
          </div>
          <NoDataFoundError imageSrc={taken} condition={data.length < 1 && (status === "succeeded" || status === "idle")} errorMsg="No Product available" />
          <NoDataFoundError imageSrc={warning} condition={status === "failed"} errorMsg={error || "Something went wrong"} />
        </div>
      </MainLayout>
    </>
  );
};

export default CategoryProductsPage;

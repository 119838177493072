import React, { useEffect, useState } from "react";
import IPage from "../interfaces/page";
import logging from "../config/logging";
import { RouteComponentProps } from "react-router-dom";

const AboutPage: React.FunctionComponent<IPage & RouteComponentProps<any>> = (props) => {
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
    document.title = `${props.name} - Adehot`;
    let number = props.match.params.number;

    if (number) {
      setMessage(`The Number is ${number}`);
    } else {
      setMessage(`No number provided!`);
    }
  }, [props]);

  return (
    <div>
      <p>{message}</p>
    </div>
  );
};

export default AboutPage;

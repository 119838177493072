
import slugify from "slugify";
import IRouthPaths from "../interfaces/routePaths";

export const home: string = "/";
export const about: string = "/about";
export const login: string = "/login";
export const register: string = "/register";
export const error404: string = "/404";
export const cart: string = "/cart";
export const newPassword: string = "/newPassword"
export const confirmEmail: string = `/confirmemail/:userId/:token`
export const confirmEmailPath = (userId: string, token: string): string => {
    return `/confirmemail/${userId}/${token}`;
};
export const categoryPath = (category: string, categoryId: number | string): string => {
    return `/products/category/${slugify(category, { lower: true })}/${categoryId}`;
};
export const products: string = "/products";
export const category: string = "/products/category/:slug/:categoryId";
export const product: string = "/products/:name/:productId";
export const profile: string = "/profile";
export const checkout: string = "/checkout";
export const resetPassword: string = "/reset-password";
export const preferences: string = "/preferences";
export const logout: string = "/logout";
export const orderHistory: string = "/order/history";
export const order: string = "/order";

export const routePaths: IRouthPaths = {
    home,
    about,
    login,
    register,
    error404,
    confirmEmail,
    products,
    category,
    cart,
    product,
    checkout,
    newPassword,
    resetPassword,
    profile,
    preferences,
    logout,
    orderHistory,
    order,
}
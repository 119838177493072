import React from 'react';
import './change.css'
const ChangeAddress = props => {
    const [close, setClose] = React.useState('false')

    const styles={
        display:close
    }
    return (
        <div>
           <div className="modal-parent" style={styles}>
            
                <div className="container">


                    <div className="modalbox">

                        <div>
                            <h1 className='collabs' onClick={e => {
                                setClose('none')
                                if (close === 'none') {
                                    setClose('block')
                                } else {
                                    setClose('none')
                                }
                            }}>&times;</h1>
                            <form>
                                <div className="form-group">
                                    <label htmlFor=""> Input Address </label>
                                    <br />
                                    <input type="text" className="form-control" id="address" placeholder="Enter Address" />
                                </div>

                                <div className="form-group">
                                    <button className='btn btn-success' onclick={() => props.setAddress(this.target.value)}> Change Address </button>
                                </div>

                 </form>    
                </div>
            </div>
                </div>

            </div>
        </div >
    )
}

export default ChangeAddress;
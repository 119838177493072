import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// import api from "../../apis/axios";
import { getProduct } from "../../apis/requests/products";
// import { ErrorHandler } from "../../utils/ErrorHandler";

export type SingleProductData = {
    id: number;
    name: string;
    description: string;
    price: string;
    extras?: string;
    rating?: 0.5 | 1 | 1.5 | 2 | 2.5 | 3;
    images: any[];

}

type SingleProductSlice = {
    status: "idle" | "loading" | "succeeded" | "failed";
    error: string | null;
    data: SingleProductData | null;
}

export const _getProduct: any = createAsyncThunk<any, { rejectValue: any }>(
    "Product/GetSingleProduct",
    async (productId: any, { rejectWithValue }) => {
        return getProduct(productId)
    }
);

const productSlice = createSlice({
    name: "products",
    initialState: {
        status: "idle",
        error: null,
        data: null
    } as SingleProductSlice,
    reducers: {},
    extraReducers: {
        [_getProduct.pending]: (
            state: SingleProductSlice
        ) => {
            state.status = "loading";
            state.error = null;
            state.data = null;
        },
        [_getProduct.fulfilled]: (
            state: SingleProductSlice,
            action: PayloadAction<any>
        ) => {
            state.status = "succeeded";
            // console.log(action.payload)
            state.data = action.payload.data;
            state.error = null;
        },
        [_getProduct.rejected]: (
            state: SingleProductSlice,
            action: PayloadAction<any>
        ) => {
            state.status = "failed";
            state.error = action.payload;
            state.data = null;
        },
    }
})

export const getProductState = (state: SingleProductSlice) => state?.data;
export default productSlice.reducer;
// export const  { }  = productSlice.actions;
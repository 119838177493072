import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../assets/styles/styles.css";
import { Link } from "react-router-dom";

function Sliders({ data, title }) {
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
    ],
  };
  return (
    <>
      <div className="pb-3">
        <h4>{title}</h4>
      </div>
      <Slider {...settings}>
        {data.map((item, i) => (
          <div key={i}>
            <center>
              <img loading="lazy" src={item.image} alt="" />
              <h6 className="mt-2">
                <Link to={item.link} className="text-dark">
                  {item.name}
                </Link>
              </h6>
            </center>
          </div>
        ))}
      </Slider>
    </>
  );
}

export default Sliders;

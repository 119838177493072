import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// import api from "../../apis/axios";
import { getCategoryProducts } from "../../apis/requests/products";
import { ProductSlice } from "./productsSlice";
// import { ErrorHandler } from "../../utils/ErrorHandler";


export const _getCategoryProducts: any = createAsyncThunk<any, { rejectValue: any }>(
    "CategoryProduct/GetAll",
    async (categoryId: any, { rejectWithValue }) => {
        return getCategoryProducts(categoryId)
    }
);

const categoryProductsSlice = createSlice({
    name: "categoryProducts",
    initialState: {
        status: "idle",
        error: null,
        data: []
    } as ProductSlice,
    reducers: {},
    extraReducers: {
        [_getCategoryProducts.pending]: (state: ProductSlice) => {
            state.status = "loading";
            state.error = null;
            // state.data = [];
        },
        [_getCategoryProducts.fulfilled]: (state: ProductSlice, action: PayloadAction<any>) => {
            state.status = "succeeded";
            state.data = action.payload.data.data;
            state.error = null;
        },
        [_getCategoryProducts.rejected]: (state: ProductSlice, action: PayloadAction<any>) => {
            state.status = "failed";
            state.error = action.payload;
            // state.data = [];
        },
    }
})

export const getCategoryProductState = (state: ProductSlice) => state?.data;
export default categoryProductsSlice.reducer;
// export const { _getCategoryProducts }  = productSlice.actions;
import ProductPanel from "../components/commerce/productPanel";
import { NoDataFoundError } from "../components/common/Error";
import warning from "./../assets/icons/warning.svg"
import { useEffect } from 'react';
import MainLayout from '../components/layouts/main';
import { RouteComponentProps } from 'react-router-dom';
import IPage from '../interfaces/page';
import { BsStarFill } from 'react-icons/bs';
import { BsStarHalf } from 'react-icons/bs';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { _getProduct } from '../redux/slices/productSlice';

const ProductPage: React.FunctionComponent<IPage & RouteComponentProps<any>> = (props) => {
  const productId = props.match.params.productId;

  const iconstyle = { color: '#FBC218', fontSize: '1.2em' };


  useEffect(() => {
    document.title = `${props.name} - Adehot`;
  }, [props.name]);

  const { data: product, status, error } = useAppSelector(state => state.product);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // if (status === "idle") {
    dispatch(_getProduct(productId));
    // }
  }, [dispatch, productId])

  // const [categories, setCategories] = useState(data);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(_getProduct(productId));
    }
  }, [status, dispatch, productId]);

  var ratings: Array<any> = [];
  for (let index = 1; index <= 3; index++) {
    var ratings2 = ((product?.rating as number) || 3) - index;
    if (ratings2 < 0) {
      ratings.push(<BsStarHalf key={index} style={iconstyle} />);
      break;
    } else {
      ratings.push(<BsStarFill key={index} style={iconstyle} />);
    }
  }



  return (
    <>
      <MainLayout container hideCategory>
        <>
          {product && status === "succeeded" &&
            <>
              <div className="row mt-4">
                <ProductPanel product={product} status={status} />
              </div>
            </>
          }
          <NoDataFoundError imageSrc={warning} condition={status === "failed"} errorMsg={error || "Something went wrong"} />
          {status === "loading" && <ProductPanel product={undefined} status={status} />}
        </>
      </MainLayout >
    </>
  );
};

export default ProductPage;

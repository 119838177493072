import "./styles.css";
import { FaCreditCard } from "react-icons/fa";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { useState } from "react";
import { usePaystackPayment } from 'react-paystack';
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { PaystackProps } from "react-paystack/dist/types";
import { Checkout, OrderCreationDto } from "../../apis/requests/cart";
import { redirectTo } from "../../pages/application";
import { routePaths } from "../../config/routePaths";
import { toast } from "react-toastify";
import { clearCart } from "../../redux/slices/cartSlice";
let { REACT_APP_PAYSTACK_PK } = process.env;
const PaymentComp = (props: { address?: any, email?: any, deliveryMethod?: any }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let { email, deliveryMethod } = props;
  const [btnState, setBtnState] = useState(false);
  const dispatch = useAppDispatch();
  const { data: cartItems, total } = useAppSelector(state => state.cart);
  const { user } = useAppSelector(state => state.auth);

  const config = {
    reference: (new Date()).getTime().toString(),
    email: email,
    amount: total * 100, // converts to kobo
    publicKey: REACT_APP_PAYSTACK_PK,
  } as PaystackProps;

  const onSuccess = (reference: any) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);

    //call backend api to checkout
    let data = {
      id: 0,
      userId: user?.id || 0,
      // statusId: (reference.status === "success" || reference.message === "Approved") ? "Awaiting Fulfillment" : "Awaiting Payment",
      statusId: (reference?.status === "success" && reference?.message === "Approved") ? 4 : 5,
      orderReference: JSON.stringify(reference),
      totalOrderPrice: total,
      paymentType: paymentMethod,
      deliveryType: deliveryMethod,
      cartItems
    } as OrderCreationDto;

    Checkout(data, dispatch, setBtnState).then(() => {
      redirectTo(routePaths.products, toast.success, `Order ${reference?.message}`)
      setBtnState(false);
    }).catch(() => {
      setBtnState(false);
    });
    dispatch(clearCart())
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }

  const initializePayment = usePaystackPayment(config);

  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);
  return (
    <div>
      <div onClick={() => setPaymentMethod("Card")} className={`${paymentMethod === "Card" ? "tw-border-2 tw-border-jungle-green" : ""} cards my-2`}>
        <div className="circle">
          <FaCreditCard size={25} />
        </div>
        <div className="">
          <h1 className="float-left title">Pay with your Debit Card</h1>
          <p className="float-left sub_title">Order and pay with your card </p>
        </div>
      </div>
      <button disabled onClick={() => setPaymentMethod("Cash")} className={`${paymentMethod === "Cash" ? "tw-border-2 tw-border-jungle-green" : ""} cards my-2`}>
        <div className="circle">
          <AiOutlineCodeSandbox size={25} />
        </div>
        <div className="">
          <h1 className="float-left title">Cash on Delivery</h1>
          <p className="float-left sub_title">
            Order and pay for product to delivery agent
          </p>
        </div>
      </button>
      <button disabled={paymentMethod == null || btnState} type="button" onClick={() => { initializePayment(onSuccess, onClose) }} className="btn btn-success float-right mt-3">
        Checkout
      </button>
    </div>
  );
}

export default PaymentComp;

import gadgets from "../constants/data/gadgets.js";
import newArrivals from "../constants/data/newArrivals";
import nigeriaMade from "../constants/data/nigeriaMade";
import Sectioner from "../components/home/Sectioner";
import Sliders from "../components/home/Sliders";
import SwiperBg from "../components/home/SwiperBg";
import MainLayout from "../components/layouts/main";

function HomePage() {
  return (
    <>
      <MainLayout>
        <section className="bg-holder">
          <SwiperBg />
          <Sectioner />
        </section>
        <section className="slide mx-auto mb-3" id="apex">
          <Sliders data={newArrivals} title="Newest Arrivals" />
        </section>
        <section className="sect mx-auto mb-3">
          <div className="pb-3">
            <h4>Explore Popular Product</h4>
          </div>
        </section>
        <section className="slide mx-auto mb-3">
          <Sliders data={gadgets} title="Mens Footwear" />
        </section>
        <section className="sect mx-auto mb-3">
          <div className="pb-3">
            <h4>Explore Popular Product</h4>
          </div>
        </section>
        <section className="slide mx-auto mb-3">
          <Sliders data={gadgets} title="Hottest Gadgets" />
        </section>
        <section className="slide mx-auto mb-3">
          <Sliders data={nigeriaMade} title="Naija Made Products" />
        </section>
      </MainLayout>
    </>
  );
}

export default HomePage;

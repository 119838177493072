import MainLayout from "../../components/layouts/main";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiOutlineHome } from "react-icons/ai";
import { SiNintendogamecube } from "react-icons/si";
import { FaStore } from "react-icons/fa";
import { MdSell } from "react-icons/md";
import { ImGift } from "react-icons/im";
import { useAppSelector } from "../../redux/store";

function Profiles() {
  const { user } = useAppSelector((state) => state.auth);

  return (
    <MainLayout hideCategory>
      <div className="container mt-5">
        <h4 id="settings_title">My Account</h4>
        <div className="row mt-5">
          <div className="col-md-5">
            <div
              className="col-md-11 d-flex flex-column  align-items-center"
              style={{
                background: "#FCFCFC", height: "700px",
                borderRadius: '15px',
                fontWeight: 100,
              }}
            >
              <div className="mt-5">
                <div
                  className="holder mb-4"
                  style={{ background: "rgb(9, 197, 156)" }}
                >
                  <img
                    loading="lazy"
                    className="img-fluid"
                    alt={user.username}
                    src={`https://ui-avatars.com/api/?name=${user.email}&rounded=true&background=09c59c&color=fff`}
                  />
                </div>
                <h1 className="mb-5 texts">{`${user.firstName} ${user.lastName}`}</h1>
              </div>
              <div className="">
                <div className="d-flex align-items-center mb-4">
                  <AiOutlineHome size={20} className="mr-2" />
                  <h5 className="texts">Adehot Home </h5>
                </div>
                <div className="d-flex align-items-center mb-4 mt-1">
                  <SiNintendogamecube size={20} className="mr-2" />
                  <h5 className="texts">Orders</h5>
                </div>
                <div className="d-flex align-items-center mb-4 mt-1">
                  <FaStore size={20} className="mr-2" />
                  <h5 className="texts">Adehot Marketplace</h5>
                </div>
                <div className="d-flex align-items-center mb-4 mt-1">
                  <MdSell size={20} className="mr-2" />
                  <h5 className="texts">Buy and Sell on Adehot</h5>
                </div>
                <div className="d-flex align-items-center mb-4 mt-1">
                  <ImGift size={20} className="mr-2" />
                  <h5 className="texts">Gift Card</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <div className="col-md-12 pt-3">
              <h6 id="profile_title" className="mb-2 text-muted">
                Account Details
              </h6>
              <div
                className="pt-5 pl-3 pr-2 text-muted"
                style={{
                  background: "#FDFDFD",
                  height: "300px",
                  borderRadius: '15px',
                  overflow: 'hidden',
                  fontWeight: 100

                }}
              >
                <div className="d-flex align-items-center mb-4">
                  <AiOutlineMail size={22} className="mr-2" />
                  <h5 className="texts">{user.email}</h5>
                </div>
                {user.userAddress?.addressLine1 ?
                  <div className="d-flex align-items-center mb-4">
                    <HiOutlineLocationMarker size={22} className="mr-2" />
                    <h5 className="texts">{`${user.userAddress.addressLine1}, ${user.userAddress.city}, ${user.userAddress.state}, ${user.userAddress.country} ${user.userAddress.postalCode ? `({user.userAddress.postalCode})` : ""}`}</h5>
                  </div> : null
                }
                {user.userAddress?.addressLine2 ?
                  <div className="d-flex align-items-center mb-4">
                    <HiOutlineLocationMarker size={22} className="mr-2" />
                    <h5 className="texts">{`${user.userAddress.addressLine2}, ${user.userAddress.city}, ${user.userAddress.state}, ${user.userAddress.country} ${user.userAddress.postalCode ? `({user.userAddress.postalCode})` : ""}`}</h5>
                  </div> : null
                }
                <div className="d-flex align-items-center mb-4">
                  <BsTelephone size={22} className="mr-2" />
                  <h5 className="texts">{user.phoneNumber}</h5>
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-3">
              <h6 id="profile_title" className="mb-2 text-muted">
                Settings
              </h6>
              <div
                className="pt-5 pl-4 pr-4 "
                style={{
                  background: "#FDFDFD",
                  height: "330px",
                  borderRadius: '15px',
                  padding: '4em',
                  fontWeight: 100

                }}
              >
                <div className="password" >
                  <div className="d-flex align-items-center mb-4">
                    <RiLockPasswordFill size={20} className="mr-2" />
                    <h5 className="texts text-muted">Change password</h5>
                  </div>

                  <div className="form-group">
                    <input type="number" className="form-control p-2" placeholder='New Password' />
                  </div>

                </div>
                <div className="address">
                  <div>
                    <div className="d-flex align-items-center mb-4">
                      <HiOutlineLocationMarker size={20} className="mr-2" />
                      <h5 className="texts text-muted">Add a pickup address</h5>
                    </div>
                  </div>
                  <div className="form-group">
                    <input type="number" className="form-control p-2" placeholder='Address' />
                  </div>
                  <button className="btn btn-success text-align-center text-center btn-block pt-3 pb-3 ">Update Settings</button>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Profiles;

import React, { useEffect } from 'react';
import IPage from '../../interfaces/page';
import logging from '../../config/logging';
import AuthSideImage from '../../components/authSideImage';
import RegisterForm from '../../components/registerForm';

const RegisterPage: React.FunctionComponent<IPage> = (props) => {
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
    document.title = `${props.name} - Adehot`;
  }, [props.name]);

  return (
    <>
      <section className="tw-min-h-screen tw-flex tw-items-stretch">
        <AuthSideImage />
        <RegisterForm />
      </section>
    </>
  );
};

export default RegisterPage;

import "./styles.css";
import { Link } from "react-router-dom";
import { routePaths } from "../../../config/routePaths";
import { FiUser } from 'react-icons/fi';
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { _logout } from "../../../redux/slices/authSlice";
import { redirectTo } from "../../../pages/application";
import { toast } from "react-toastify";

const Log = () => {
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAppSelector(state => state.auth);

  // const logout = () => {
  //   dispatch(_logout());
  // };

  return (
    <div className="ml-3 dropdown">
      <div
        className="d-flex dropdown-toggle  align-items-center p-1"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <FiUser className="font-round-b-bottom" size={35} />
        {/* <i className="fa fa-user text-muted mr-2"></i> */}
        <div className="">
          <span>
            <span className="tw-font-thin log-text-up tw-text-xs">{user == null ? "Sign In" : user.lastName}</span>
            <h6 className="text-center log-text-down">Account</h6>
          </span>
        </div>
      </div>
      <div className="dropdown-menu p-4 log-dropdown" aria-labelledby="dropdownMenuButton">
        {user == null ?
          <>
            <Link className="dropdown-link" to={routePaths.login}>Login</Link>
            <Link className="dropdown-link btn tw-bg-adehot" to={routePaths.register}>Signup</Link>
          </> :
          <>
            <Link className="dropdown-link" to="/profile">Profile</Link>
            <button className="mx-auto dropdown-link btn tw-bg-adehot" onClick={() => { dispatch(_logout()); redirectTo("/", toast.success, "Logout Successfull") }}>Logout</button>
          </>
        }
      </div>
    </div>
  );
}

export default Log;

import './order.css';
const Orderpanel = props => {

    return (

        <>
            <div className="  p-4 order  ">

                {/* first property */}
               <div class='inner-div'>
               <div className="img">
                    <img src="https://via.placeholder.com/150" alt=""/>
                </div>


                <div className="details">
                    <div className="small">
                        <h2>{props.name}</h2>
                        <p>{props.descriptioin}</p>
                    </div>

                    <span className="qty mt-4">
                        <small className="mt-4">Quantity: {props.qty} </small>
                    </span>
                </div>

               </div>


                {/* second property */}

                <div className="second">

                    <h3 className='price'>{props.price}</h3> 

                    <span>
                        <div className="badge badge-success p-2 mt-5">
                            <p>{props.status}</p>
                        </div>
                    </span>

                </div>


            </div>

        </>

    )

}

export default Orderpanel;
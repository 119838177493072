import { BiCart } from "react-icons/bi";
import { Link } from "react-router-dom";
import { routePaths } from "../../../config/routePaths";
import { useAppSelector } from "../../../redux/store";

function Cart() {
  const { quantity } = useAppSelector(state => state.cart);

  return (
    <Link to={routePaths.cart} className="link-btn d-flex align-items-center">
      <BiCart className="" size={30} />
      {/* <i className="fa fa-shopping-cart cart-icon mr-2"></i> */}
      <h6 className="cart-text">cart</h6>
      {quantity > 0 && (
        <span
          className="badge tw-bg-adehot tw-text-white badge-sm mb-3 ml-1"
          style={{ borderRadius: "100%" }}
        >
          {quantity}
        </span>
      )}
    </Link>
  );
}

export default Cart;

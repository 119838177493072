import React, { useEffect } from "react";
import IPage from "../interfaces/page";
import logging from "../config/logging";
import { Link } from "react-router-dom";

const Error404Page: React.FunctionComponent<IPage> = (props) => {
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
    document.title = `${props.name} - Adehot`;
  }, [props.name]);

  return (
    <>
      <div className="tw-bg-gradient-to-r tw-from-jungle-green tw-via-green-700 tw-to-deep-sea-green">
        <div
          className="
                tw-w-9/12
                tw-m-auto
                tw-py-16
                tw-min-h-screen
                tw-flex
                tw-items-center
                tw-justify-center
            "
        >
          <div className="tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg tw-pb-8">
            <div className="tw-border-t tw-border-gray-200 tw-text-center pt-8">
              <h1 className="tw-text-9xl tw-font-bold tw-text-green-600">
                404
              </h1>
              <h1 className="tw-text-6xl tw-font-medium tw-py-8">
                Oops! Page not found
              </h1>
              <p className="tw-text-2xl tw-pb-8 tw-px-12 tw-font-medium">
                The page you are looking for does not exist. It might have
                been moved or deleted.
              </p>
              <Link
                to="/"
                className="
                    tw-bg-gradient-to-r
                    tw-from-green-400
                    tw-to-green-500
                    hover:tw-from-green-400 hover:tw-to-green-900
                    tw-text-white
                    tw-font-semibold
                    tw-px-6
                    tw-py-3
                    tw-rounded-md
                    tw-mr-6
                    hover:tw-text-gray-100
                "
              >
                HOME
              </Link>
              <Link
                to="/"
                className="
                    tw-bg-gradient-to-r
                    tw-from-blue-600
                    tw-to-blue-500
                    hover:tw-from-blue-600 hover:tw-to-blue-800
                    tw-text-white
                    tw-font-semibold
                    tw-px-6
                    tw-py-3
                    tw-rounded-md
                    hover:tw-text-gray-100
                "
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error404Page;

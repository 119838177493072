import React from "react";
import Input from "./form/input";
import { ButtonSuccess } from "./form/button";
import chair2 from "../assets/images/chair2.png";
import '../assets/styles/styles.css'
const ResetPassword = () => { 

   const  handleSubmit = (e) => {
    e.preventDefault();
    
  }
      

    return (
    <>
        <div className="container-fluid ">
            
            <div className="row d-flex align-items-center" >

                <div className="col-md-6 col-sm-12 col-lg-6 col-xs-6" style={{height:'100vh', backgroundColor:'#189B62',position:"relative"}} >

                    <div className="img d-flex justify-content-center">
                        <img loading="lazy" src={chair2} alt="" style={{position:"absolute",bottom:0}}/>
                    </div>

                </div>

                <div className="col-md-6 col-sm-12 col-lg-6 col-xs-6 " style={{padding:"4em"}}>
                    
                        <div className="title mb-3">
                        <h1><b>Reset Password</b></h1>

                        <p className='mt-3'>A reset link will be sent to your email address</p>
                       </div>

                    <form onSubmit={handleSubmit} >
                            <div className="form-group">
                             <Input type="password" name="password" placeholder="Enter your Email adress " />
                            </div>

                         
                            
                            <div className="form-group d-flex justify-content-center">
                              <ButtonSuccess type="submit" name="submit" content='Reset'/>
                            </div>
                    </form>

                </div>

            </div>
        </div>
    </>
    )

}
export default ResetPassword;
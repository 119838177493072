import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: false,
  arrows: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 760,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

const OtherProductImages = ({ images }) => {
  return (
    <div className="more_images_container mt-4 mr-0 bg-light px-3">
      <Slider {...settings}>
        {images && images.map((img, index) => (
          <div className="product-images-container">
            <img
              key={index}
              src={img?.imageUrl}
              alt=""
              className="w-100 h-100"
              loading="lazy"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default OtherProductImages;

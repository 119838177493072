import React, { useEffect, useState } from "react";
import IPage from "../../interfaces/page";
import { RouteComponentProps } from "react-router-dom";
import AuthSideImage from "../../components/authSideImage";
import ConfirmEmailMessage from "../../components/confirmEmailMessage";

const ConfirmEmailPage: React.FunctionComponent<
  IPage & RouteComponentProps<any>
> = (props) => {
  const [userId, setUserId] = useState<string>('');
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    // logging.info(`Loading ${props.name}`);
    let { userId, token } = props.match.params;
    document.title = `${props.name} - Adehot`;

    setUserId(userId);
    setToken(token);
  }, [props]);

  return (
    <section className="tw-min-h-screen tw-flex tw-items-stretch">
      <AuthSideImage />
      <ConfirmEmailMessage userId={userId} token={token} />
    </section>
  );
};

export default ConfirmEmailPage;

import IInput from "../../interfaces/input";

const Input = (props: IInput) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      className={`tw-block tw-w-full tw-p-4 tw-text-lg tw-rounded-sm tw-bg-gray-100 ${
        props.touched && props.error
          ? "tw-border tw-border-red-500 "
          : ""
      }`}
      onChange={props.onChange}
      onBlur={props.onBlur}
      value={props.value}
    />
  );
};

export default Input;

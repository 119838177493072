import IButton from "../../interfaces/button";
import { Spin } from '../common/spinner'

export const ButtonSuccess = (props: IButton) => {
  return (
    <>
      {props.onClick &&
        <button onClick={props.onClick} type={props.type} disabled={props.disabled} className="tw-rounded-lg px-5 text-center tw-items-center tw-uppercase tw-block  tw-p-4 disabled:tw-opacity-50 disabled:cursor-not-allowed tw-text-lg  bg-adehot  hover:tw-bg-bush tw-text-white focus:tw-outline-none ">
          <div> {props.disabled && <Spin />} {props.content}</div>
        </button>
      }
      {!props.onClick &&
        <button type={props.type} disabled={props.disabled} className="tw-rounded-lg px-5 text-center tw-items-center tw-uppercase tw-block  tw-p-4 disabled:tw-opacity-50 disabled:cursor-not-allowed tw-text-lg  bg-adehot  hover:tw-bg-bush tw-text-white focus:tw-outline-none ">
          <div> {props.disabled && <Spin />} {props.content}</div>
        </button>
      }
    </>
  );
};

export const ButtonSuccessMd = (props: IButton) => {
  const className = "btn btn-sm btn-block disabled:tw-opacity-50 disabled:cursor-not-allowed bg-adehot  hover:tw-bg-bush tw-text-white focus:tw-outline-none"
  return (
    <>
      {/* {props.onClick && */}
      <button onClick={props.onClick} type={props.type} disabled={props.disabled} className={className}>
        <div> {props.icon && !props.disabled && <i>{props.icon}</i>} {props.disabled && <Spin />} {props.content}</div>
      </button>
      {/* } */}
      {/* {!props.onClick && */}
      {/* <button type={props.type} disabled={props.disabled} className={className}> */}
      {/* <div> {props.icon && !props.disabled && <Spin />}{props.disabled && <Spin />}{props.content}</div> */}
      {/* </button> */}
      {/* } */}
    </>
  );
};

export const Button = (props: IButton) => {
  return (
    <>
      <button {...props.extras} onClick={props.onClick} type={props.type} disabled={props.disabled} className={props.className}>
        <div> {props.icon && !props.disabled && <i>{props.icon}</i>} {props.disabled && <Spin />} {props.content}</div>
      </button>
    </>
  );
};

// export default ButtonSuccess;

export const NoDataFoundError = (props: { condition: boolean, errorMsg: string, imageSrc: string }) => {
    return (
        <>
            {props.condition &&
                <div>
                    <div className="mx-auto tw-w-full my-4">
                        <img alt={props.errorMsg} className="tw-h-50 sm:tw-h-60 md:tw-h-70 lg:tw-h-80 mx-auto my-4" src={props.imageSrc} />
                        <p className="text-center mt-5 tw-font-medium sm:tw-font-semibold lg:tw-font-bold tw-text-lg sm:tw-text-xl lg:tw-text-2xl">{props.errorMsg}</p>
                    </div>
                </div>
            }
        </>
    )
}
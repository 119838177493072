export const Spin = () => {
    return (
        <>
            <i className='fa fa-spinner fa-spin'></i>
        </>
    )
}

export const Icon = (props: { name: string }) => {
    return (
        <>
            <i className={`fa fas fab ${props.name}`}></i>
        </>
    )
}
import { FlattenObject } from "./FlattenObject";

export const ErrorHandler = (error: any) => {
    if (error.response?.data.errors && typeof error.response.data.errors === 'string') {
        return error.response?.data.errors;
    }
    else if (error.response?.data.errors && typeof error.response?.data.errors === 'object') {
        return FlattenObject(error.response?.data.errors)
    }
    else if (error.response?.data.message) {
        return error.response.data.message;
    }
    else if (error.response?.data.title) {
        if (error.response?.data.errors && typeof error.response?.data.errors === 'object') {
            return FlattenObject(error.response?.data.errors)
        }
        else {
            return error.response.data.title;
        }
    } else if (error?.message) {
        return error?.message
    }
    else {
        return "Unknown Error"
    }
}

export const catchAsync = (callback: Function) => {
    try {
        return callback();
    } catch (err: any) {
        throw ErrorHandler(err) || "unable to load endpoint";
    }
}
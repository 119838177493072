import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// import api from "../../apis/axios";
import { getProducts } from "../../apis/requests/products";
// import { ErrorHandler } from "../../utils/ErrorHandler";

export type ProductData = {
    id: number;
    name: string;
    price: number;
    discountPrice: number | null;
    image: string;
    description: string | null;
}

export type ProductSlice = {
    status: "idle" | "loading" | "succeeded" | "failed";
    error: string | null;
    data: Array<ProductData>
}

export const _getProducts: any = createAsyncThunk<any, { rejectValue: any }>(
    "Product/GetAll",
    async (data: any, { rejectWithValue }) => {
        return getProducts()
    }
);

const productSlice = createSlice({
    name: "products",
    initialState: {
        status: "idle",
        error: null,
        data: []
    } as ProductSlice,
    reducers: {},
    extraReducers: {
        [_getProducts.pending]: (state: ProductSlice) => {
            state.status = "loading";
            state.error = null;
            // state.data = [];
        },
        [_getProducts.fulfilled]: (state: ProductSlice, action: PayloadAction<any>) => {
            state.status = "succeeded";
            state.data = action.payload.data.data;
            state.error = null;
        },
        [_getProducts.rejected]: (state: ProductSlice, action: PayloadAction<any>) => {
            state.status = "failed";
            state.error = action.payload;
            // state.data = [];
        },
    }
})

export const getProductState = (state: ProductSlice) => state?.data;
export default productSlice.reducer;
// export const { _getProducts }  = productSlice.actions;
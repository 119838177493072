const nigeriaMade = [
    {
    name: "",
    image: require("../../assets/images/nmp1.png").default,
    link: "/"

    },
    {
        name: "",
        image: require("../../assets/images/nmp2.png").default,
        link: "/"

    },
    {
        name: "",
        image: require("../../assets/images/nmp3.png").default,
        link: "/"

    },
    {
        name: "",
        image: require("../../assets/images/nmp4.png").default,
        link: ""

    },
    {
        name: "",
        image: require("../../assets/images/tv.png").default,
        link: "/"

    },
    {
        name: "",
        image: require("../../assets/images/makeup.png").default,
        link: "/"

    },
    {
        name: "",
        image: require("../../assets/images/toyz.png").default,
        link: "/"

    },
    {
        name: "",
        image: require("../../assets/images/tv.png").default,
        link: "/"

    },
]

export default nigeriaMade;
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../apis/axios';
import { ErrorHandler } from '../../utils/ErrorHandler';

export const _signin: any = createAsyncThunk<any, { rejectValue: any }>(
  'account/login',
  async (data: any, { rejectWithValue }) => {
    let { values } = data;
    try {
      const response = await api({
        method: 'post',
        url: 'Account/Login',
        data: values,
      });
      return response.data;
    } catch (err: any) {
      const error = ErrorHandler(err);
      return rejectWithValue(error || 'unable log in');
    }
  }
);

export const _signup: any = createAsyncThunk<any, { rejectValue: any }>(
  'account/register',
  async (data: any, { rejectWithValue }) => {
    let { values } = data;
    try {
      const response = await api({
        method: 'post',
        url: 'Account/Register',
        data: values,
      });
      return response.data;
    } catch (err: any) {
      const error = ErrorHandler(err);
      return rejectWithValue(error || 'unable log in');
    }
  }
);

export const _currentUser: any = createAsyncThunk<any, { rejectValue: any }>(
  'Account/CurrentUser',
  async (data: any, { rejectWithValue }) => {
    const { token } = data;
    const config = { headers: { Authorization: `Bearer ${token}` } };
    try {
      const response = await api.get('Account', config);
      return { token, response: response.data };
    } catch (err: any) {
      const error = ErrorHandler(err);
      return rejectWithValue(error || 'unable log in');
    }
  }
);

type AuthState = {
  user: any;
  token: string | null;
  error: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  expiryDate: Date | null;
  message: string | null;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    error: null,
    status: 'idle',
    expiryDate: null,
    message: null,
  } as AuthState,
  reducers: {
    _logout: (state) => {
      state.user = null;
      state.token = null;
      state.status = 'idle';
      state.error = null;
      state.message = null;
      state.expiryDate = null;
    },
  },
  extraReducers: {
    [_signin.pending]: (state: AuthState) => {
      state.status = 'loading';
      state.user = null;
      state.token = null;
      state.error = null;
      state.expiryDate = null;
    },
    [_signin.fulfilled]: (state: AuthState, action: PayloadAction<any>) => {
      state.status = 'succeeded';
      state.user = action.payload.data?.user;
      state.token = action.payload.data?.token;
      state.expiryDate = action.payload.data?.expireDate;
      state.message = action.payload.data?.message;
    },
    [_signin.rejected]: (state: AuthState, action: PayloadAction<any>) => {
      state.status = 'failed';
      state.error = action.payload;
      state.expiryDate = null;
      state.user = null;
      state.token = null;
    },
    [_currentUser.pending]: (state: AuthState) => {
      state.status = 'loading';
      state.user = null;
      state.token = null;
      state.error = null;
    },
    [_currentUser.fulfilled]: (
      state: AuthState,
      action: PayloadAction<any>
    ) => {
      state.status = 'succeeded';
      state.user = action.payload.response.data;
      // state.token = action.payload.token;
      // saveToken(`${action.payload.token}`);
    },
    [_currentUser.rejected]: (state: AuthState, action: PayloadAction<any>) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const { _logout } = authSlice.actions;
export default authSlice.reducer;

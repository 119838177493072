import React from 'react';
// import { FaUserCircle } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { FiHome } from 'react-icons/fi';
import { BsBoxSeam, BsCreditCard2Back } from 'react-icons/bs';
import { BsShop } from 'react-icons/bs';
import { MdOutlineSell } from 'react-icons/md';
import { IoIosLogOut, IoIosLogIn, IoIosPerson } from 'react-icons/io';
import { routePaths } from './../../config/routePaths';

import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../redux/store';
import { _logout } from '../../redux/slices/authSlice';
import { redirectTo } from '../../pages/application';
import { toast } from "react-toastify";

function Sidebar({ isopen, togglenav }) {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  return (
    <div id="sidebar" className={isopen ? 'open' : 'close'}>
      {user ? (
        <div className="sidebar_header">
          <div
            className=" pl-4 pt-2 pr-3 float-right"
            onClick={(e) => togglenav()}
          >
            <FaTimes size={20} style={{ color: 'white' }} />
          </div>
          <div className="con">
            <div className="holder p-2">
              <img
                loading="lazy"
                className="img-fluid"
                alt={user.username}
                src={`https://ui-avatars.com/api/?name=${user.email}&rounded=true&background=ccc`}
              />
            </div>
            <div className="">
              <h3 className="sidebar_name text-light">{`${user.firstName} ${user.lastName}`}</h3>
              <h5 className="sidebar_email text-light">{user.email}</h5>
            </div>
          </div>
        </div>
      ) : (
        <div
          className=" pl-4 pt-4 pr-3 float-right"
          onClick={(e) => togglenav()}
        >
          <FaTimes size={20} style={{ color: '#28a745' }} />
        </div>
      )}
      <div className="sidebar_body pl-4 pt-4">
        <div className="">
          <div className="d-flex align-items-center my-5">
            <FiHome size={25} />
            <Link to={routePaths.home} className="list ml-3">
              Home
            </Link>
          </div>
          {user &&
            <div className="d-flex align-items-center my-5">
              <BsBoxSeam size={25} />
              <Link to={routePaths.orderHistory} className="list ml-3">
                Orders
              </Link>
            </div>
          }
          <div className="d-flex align-items-center my-5">
            <BsShop size={25} />
            <Link to={routePaths.products} className="list ml-3">
              Marketplace
            </Link>
          </div>
          <div className="d-flex align-items-center my-5">
            <MdOutlineSell size={25} />
            <Link to={routePaths.products} className="list ml-3">
              Buy and Sell
            </Link>
          </div>
          <div className="d-flex align-items-center my-5">
            <BsCreditCard2Back size={25} />
            <Link to={routePaths.products} className="list ml-3">
              Gift Cards
            </Link>
          </div>
        </div>
        {user ? (
          <>
            <div
              className="logout_sidebar red pl-2 my-1"
              onClick={() => { dispatch(_logout()); redirectTo("/", toast.success, "Logout Successfull") }}
            >
              <IoIosLogOut />
              <button className="ml-1">Logout</button>
            </div>
          </>
        ) : (
          <>
            <div className="logout_sidebar yellow pl-2 my-1">
              <IoIosLogIn />
              <Link className="ml-1" to={routePaths.login}>
                Login
              </Link>
            </div>
            <div className="logout_sidebar green pl-2 my-1">
              <IoIosPerson />
              <Link className="ml-1" to={routePaths.register}>
                Register
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
